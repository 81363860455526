import { abbreviateNumber } from 'core-utilities';
import suffixNames from '../../../../../../Roblox.CoreScripts.WebApp/Roblox.CoreScripts.WebApp/js/core/utils/abbreviate/suffixNames';
import suffixes from '../../../../../../Roblox.CoreScripts.WebApp/Roblox.CoreScripts.WebApp/js/core/utils/abbreviate/suffixes';

const truncateAbbreviateNumbers = (
  abbreviatedValue: string,
  truncateLength = 3,
  suffixType = suffixNames.withoutPlus
): string => {
  const suffix = suffixType ? suffixes[suffixType] : suffixes[suffixNames.withPlus];
  let currentSuffix = '';
  let value = abbreviatedValue;

  // Extract the suffix if it exists
  const foundSuffix = suffix.find(s => s && s !== '' && abbreviatedValue.endsWith(s));
  if (foundSuffix) {
    currentSuffix = foundSuffix;
    value = abbreviatedValue.slice(0, -foundSuffix.length);
  }

  // Truncate the value
  if (value.length > truncateLength) {
    value = value.slice(0, truncateLength);
  }

  // Remove trailing period if it exists
  if (value.endsWith('.')) {
    value = value.slice(0, -1);
  }

  return value + currentSuffix;
};

const abbreviateNumberWithTruncate = (
  value: number,
  truncateLength = 3,
  suffixType = suffixNames.withoutPlus
): string => {
  const abbreviatedValue = abbreviateNumber.getAbbreviatedValue(value, suffixType);
  return truncateAbbreviateNumbers(abbreviatedValue, truncateLength, suffixType);
};

export default abbreviateNumberWithTruncate;
