import { eventStreamService } from 'core-roblox-utilities';
import { EventType } from '../constants/eventConstants';

export const logGroupPageExposureEvent = ({
  context,
  groupId,
  exposureType,
  exposureId
}: {
  groupId: number;
  exposureType: string;
  exposureId?: string;
  context: string;
}): void => {
  eventStreamService.sendEventWithTarget(EventType.GroupPageExposureEvent, context, {
    groupId,
    exposureType,
    ...(exposureId && { exposureId }) // fixes type-checking issue when undefined
  });
};

export const logGroupPageClickEvent = ({
  context,
  groupId,
  clickTargetType,
  clickTargetId
}: {
  groupId: number;
  clickTargetType: string;
  clickTargetId?: string;
  context: string;
}): void => {
  eventStreamService.sendEventWithTarget(EventType.GroupPageClickEvent, context, {
    groupId,
    clickTargetType,
    ...(clickTargetId && { clickTargetId }) // fixes type-checking issue when undefined
  });
};
