import { EnvironmentUrls } from 'Roblox';

const { groupsApi } = EnvironmentUrls;

const emotesUrlPrefix = `${groupsApi}/v1/groups`;

export default {
  emoteNameToUrl: (emoteName: string): string => {
    switch (emoteName) {
      case 'love':
        return 'https://images.rbxcdn.com/b5b844cb5da1c06f9bc8b5924541145a-reaction_love.png';
      case 'angry':
        return 'https://images.rbxcdn.com/d3ea2530d704f45b9b4cc0a29e1edad8-reaction_angry.png';
      case 'sad':
        return 'https://images.rbxcdn.com/6fcf6a5ee3c673afff3d70f17355ebae-reaction_sad.png';
      case 'very-positive':
        return 'https://images.rbxcdn.com/fa9a4e13420a7f9736d345f768011115-reaction_smile.png';
      case 'thinking':
        return 'https://images.rbxcdn.com/d819e14e2473d81bf572d5f42c50b919-reaction_thinking.png';
      case 'thumbs-up':
        return 'https://images.rbxcdn.com/5d13c332e971294e4fb47245e1dd2b3c-reaction_thumbs_up.png';
      default:
        return '';
    }
  },
  urls: {
    getGroupEmoteSetsEndpoint(groupId: number): string {
      return `${emotesUrlPrefix}/${groupId}/emotes`;
    }
  }
};
