import { EditableContentFieldHandle } from './components/EditableContentFieldInput';

interface ForumCategory {
  id: string;
  groupId: number;
  name: string;
  description: string;
  archivedBy: number | null;
}

interface ForumCategoriesResponse {
  data: ForumCategory[];
  nextPageCursor: string;
  previousPageCursor: string;
}

interface CommentContent {
  plainText: string;
}

interface Reaction {
  emoteId: string;
  reactionCount: number;
  hasUserAppliedReaction: boolean;
  areReactionCountsVisible: boolean;
}

interface ForumComment {
  id: string;
  parentId: string;
  content: CommentContent;
  createdBy: number;
  createdAt: Date;
  creatorDisplayName: string;
  updatedAt: Date;
  deletedAt: Date | null;
  threadId: string | null;
  reactions: Reaction[];
  replies: ForumComment[];
  notificationPreference?: NotificationPreferenceType;
}

interface ForumPost {
  id: string;
  groupId: number;
  categoryId: string;
  name: string;
  createdBy: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  isLocked: boolean;
  isPinned: boolean;
  isUnread: boolean;
  lastComment: ForumComment;
  firstComment: ForumComment;
  commentCount: number;
  notificationPreference?: NotificationPreferenceType;
}

interface ForumPostsResponse {
  data: ForumPost[];
  nextPageCursor: string;
  previousPageCursor: string;
}

interface ForumCommentsResponse {
  data: ForumComment[];
  nextPageCursor: string;
  previousPageCursor: string;
}

interface PostState {
  groupId: number;
  categoryId: string;
  postId: string;
  initialCommentId?: string;
  categoryName: string;
  post: ForumPost | null;
  isLoadingPost: boolean;
  loadingPostError: boolean;
  fetchPost: () => void;
  createCommentError: boolean;
  commentModeratedError: boolean;
  handleCreateComment: (args: { content: string }) => Promise<boolean>;
  handleEditComment: (args: { content: string }) => Promise<boolean>;
  replyToComment: string;
  replyCommentHighlight: string;
  replyingToAuthor: number;
  setReplyToCommentOrPost: (commentId: string, authorId: number) => void;
  setReplyToCommentReply: (parentCommentId: string, commentId: string, authorId: number) => void;
  clearReplyToComment: () => void;
  isLoadingComments: boolean;
  refetchComments: () => void;
  isFetchingNextCommentsPage: boolean;
  isFetchingPreviousCommentsPage: boolean;
  fetchNextCommentsPage: () => void;
  fetchPreviousCommentsPage: () => void;
  fetchPostNotificationPreference: () => void;
  fetchCommentNotificationPreference: (commentId: string) => void;
  togglePostNotifications: () => void;
  toggleCommentNotifications: (commentId: string) => void;
  errorLoadingComments: boolean;
  comments: ForumComment[];
  addReplies: (commentId: string, comments: ForumComment[], addToFront: boolean) => void;
  clearCommentErrors: () => void;
  handleDeleteComment: (commentId: string, parentCommentId?: string) => Promise<boolean>;
  editingComment: ForumComment | null;
  editingCommentParent: ForumComment | null;
  setEditComment: (commentId: string, parentCommentId?: string) => void;
  clearEditingComment: () => void;
  commentComposerRef: React.MutableRefObject<EditableContentFieldHandle | null>;
  commentScrollContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  mobileCommentScrollContainerRef: React.MutableRefObject<HTMLDivElement | null>;
}

interface ForumPermissionsState {
  canCreatePost: boolean;
  canCreateComment: boolean;
  canPinPost: boolean;
  canLockPost: boolean;
  canEditPost: (authorId: number) => boolean;
  canEditComment: (authorId: number) => boolean;
  canDeletePost: (authorId: number) => boolean;
  canDeleteComment: (authorId: number) => boolean;
  canBlockUser: (authorId: number) => boolean;
  canReact: boolean;
  canSubscribe: boolean;
}

enum NotificationPreferenceType {
  Invalid = 0,
  None = 1,
  All = 2,
  Personalized = 3,
  StrictPersonalized = 4
}

interface NotificationPreference {
  preference: NotificationPreferenceType;
}

export {
  ForumCategory,
  ForumCategoriesResponse,
  ForumPostsResponse,
  ForumPost,
  Reaction,
  ForumComment,
  CommentContent,
  ForumCommentsResponse,
  PostState,
  ForumPermissionsState,
  NotificationPreference,
  NotificationPreferenceType
};
