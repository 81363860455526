import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { SystemFeedbackProvider } from 'react-style-guide';
import { Group, GroupPermissions } from '../../shared/types';
import Categories from './Categories';
import PostComposer from './PostComposer';
import groupForumsConstants from '../constants/groupForumsConstants';
import Post from './Post';
import { ForumPermissionsProvider } from '../contexts/ForumPermissionsContext';
import { ForumsUIContextProvider } from '../store/forumsUIContextProvider';
import { EmotesProvider } from '../../shared/contexts/EmoteContext';
import BlockUserModal from '../components/dialogs/BlockUserDialog';
import { PostContextProvider } from '../store/postContextProvider';
import GroupForumsEarlyAccessNotice from '../components/GroupForumsEarlyAccessNotice';

export type GroupForumsProps = {
  group: Group;
  permissions: GroupPermissions;
  userId: number;
  isGroupMember: boolean;
  isEnabled: boolean;
};

const GroupForums = ({
  group,
  permissions,
  userId,
  isGroupMember,
  isEnabled
}: GroupForumsProps): JSX.Element | null => {
  if (!isEnabled) return null;

  if (!group?.id) return null;

  // Hash Type "hashbang" is needed to handle the #!/ in the url for group details tabs
  return (
    <SystemFeedbackProvider>
      <ForumsUIContextProvider>
        <EmotesProvider groupId={group.id}>
          <PostContextProvider>
            <ForumPermissionsProvider
              permissions={permissions}
              userId={userId}
              isGroupMember={isGroupMember}>
              <HashRouter hashType='hashbang'>
                <div className='section group-forums'>
                  <GroupForumsEarlyAccessNotice />
                  <Switch>
                    <Route
                      path={groupForumsConstants.router.postEditRoute}
                      render={(routeProps: {
                        match: { params: { categoryId: string; postId: string } };
                      }) => {
                        return (
                          <PostComposer
                            groupId={group.id}
                            defaultCategoryId={routeProps.match.params.categoryId}
                            editingPostId={routeProps.match.params.postId}
                          />
                        );
                      }}
                    />
                    <Route
                      path={groupForumsConstants.router.postCreateRoute}
                      render={(routeProps: { match: { params: { categoryId: string } } }) => {
                        return (
                          <PostComposer
                            groupId={group.id}
                            defaultCategoryId={routeProps.match.params.categoryId}
                          />
                        );
                      }}
                    />
                    <Route
                      path={groupForumsConstants.router.postCommentRoute}
                      render={(routeProps: {
                        match: {
                          params: { categoryId: string; postId: string; commentId: string };
                        };
                      }) => {
                        return (
                          <div className='group-forums-post-wrapper'>
                            <Post
                              groupId={group.id}
                              categoryId={routeProps.match.params.categoryId}
                              postId={routeProps.match.params.postId}
                              initialCommentId={routeProps.match.params.commentId}
                            />
                          </div>
                        );
                      }}
                    />
                    <Route
                      path={groupForumsConstants.router.postRoute}
                      render={(routeProps: {
                        match: { params: { categoryId: string; postId: string } };
                      }) => {
                        return (
                          <div className='group-forums-post-wrapper'>
                            <Post
                              groupId={group.id}
                              categoryId={routeProps.match.params.categoryId}
                              postId={routeProps.match.params.postId}
                            />
                          </div>
                        );
                      }}
                    />
                    <Route
                      path={groupForumsConstants.router.categoryRoute}
                      render={(routeProps: { match: { params: { categoryId: string } } }) => {
                        return (
                          <Categories
                            groupId={group.id}
                            paramCategoryId={routeProps.match.params.categoryId}
                          />
                        );
                      }}
                    />
                    <Route path={groupForumsConstants.router.defaultRoute}>
                      <Categories groupId={group.id} paramCategoryId={null} />
                    </Route>
                  </Switch>
                </div>
                <BlockUserModal />
              </HashRouter>
            </ForumPermissionsProvider>
          </PostContextProvider>
        </EmotesProvider>
      </ForumsUIContextProvider>
    </SystemFeedbackProvider>
  );
};

export default GroupForums;
