import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import classNames from 'classnames';
import { groupsConfig } from '../translation.config';
import { usePost } from '../contexts/PostContext';
import ReplyingToDisplay from './ReplyingToDisplay';
import EditableContentFieldInput from './EditableContentFieldInput';
import { logGroupForumsClickEvent } from '../utils/logging';

const CommentComposer = ({ translate }: WithTranslationsProps): JSX.Element => {
  const [content, setContent] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [editingContent, setEditingContent] = useState<string>('');
  const {
    replyingToAuthor,
    handleCreateComment,
    handleEditComment,
    clearReplyToComment,
    commentModeratedError,
    clearCommentErrors,
    clearEditingComment,
    editingComment,
    editingCommentParent,
    commentComposerRef,
    postId,
    groupId
  } = usePost();
  const onChange = (value: string) => {
    setContent(value);
    if (commentModeratedError) {
      clearCommentErrors();
    }
  };

  const clearContent = useCallback(() => {
    setContent('');
    if (commentComposerRef.current) {
      commentComposerRef.current.clearText();
    }
    clearReplyToComment();
    clearEditingComment();
  }, [clearEditingComment, clearReplyToComment, commentComposerRef]);

  const onSubmitClicked = useCallback(async () => {
    setIsSubmitting(true);
    const result = editingComment
      ? await handleEditComment({ content })
      : await handleCreateComment({ content });
    setIsSubmitting(false);
    if (result) {
      clearContent();
    }
    const logEventData = editingComment
      ? { clickTargetType: 'editComment', clickTargetId: editingComment.id }
      : { clickTargetType: 'createComment', clickTargetId: postId };
    logGroupForumsClickEvent({
      groupId,
      ...logEventData
    });
  }, [
    clearContent,
    groupId,
    postId,
    content,
    editingComment,
    handleCreateComment,
    handleEditComment
  ]);

  const hasNewContent = useMemo((): boolean => {
    const trimmedContent = content.trim();
    const trimmedEditingContent = editingContent.trim();
    if (trimmedEditingContent !== '') {
      return trimmedContent !== '' && trimmedContent !== trimmedEditingContent;
    }
    return trimmedContent !== '';
  }, [content, editingContent]);

  const isSubmitDisabled = useMemo(() => {
    return isSubmitting || !hasNewContent || commentModeratedError;
  }, [isSubmitting, hasNewContent, commentModeratedError]);

  // Handle keyboard interactions
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        // eslint-disable-next-line no-void
        void onSubmitClicked();
      }
    },
    [onSubmitClicked]
  );

  // Handle keyboard interactions
  const handleCloseKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        clearReplyToComment();
      }
    },
    [clearReplyToComment]
  );

  const handleCloseReplyKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        clearContent();
      }
    },
    [clearContent]
  );

  useEffect(() => {
    if (!editingComment) {
      setEditingContent('');
      setContent('');
      commentComposerRef.current?.clearText();
      return;
    }
    setEditingContent(editingComment.content.plainText);
    commentComposerRef.current?.setText(editingComment.content.plainText);
  }, [editingComment, commentComposerRef]);

  return (
    <div className='group-forums-comment-composer'>
      {replyingToAuthor !== 0 && (
        <div className='group-forums-comment-composer-header'>
          <ReplyingToDisplay userId={replyingToAuthor} />
          <div
            role='button'
            tabIndex={0}
            aria-label={translate('Action.Close')}
            onClick={clearReplyToComment}
            onKeyDown={handleCloseKeyDown}
            className='group-forums-comment-composer-close-button'>
            <span className='group-forums-comment-composer-close-icon' />
          </div>
        </div>
      )}
      {editingComment && (
        <div className='group-forums-comment-composer-header'>
          <div className='group-forums-comment-composer-editing text-default'>
            {translate(editingCommentParent ? 'Label.EditReply' : 'Label.EditComment')}
          </div>
          <div
            role='button'
            tabIndex={0}
            aria-label={translate('Action.Close')}
            onClick={clearContent}
            onKeyDown={handleCloseReplyKeyDown}
            className='group-forums-comment-composer-close-button'>
            <span className='group-forums-comment-composer-close-icon' />
          </div>
        </div>
      )}
      <div className='group-forums-comment-composer-body'>
        <EditableContentFieldInput
          ref={commentComposerRef}
          className='group-forums-comment-composer-textarea'
          textAreaClassName='group-forums-comment-composer-textarea-input'
          placeholder={translate('Label.WriteComment')}
          showCharacterCount={false}
          maxTextFieldHeight={100}
          onChange={onChange}
          autoResize
        />
        <div
          role='button'
          tabIndex={0}
          aria-label='Send'
          onClick={!isSubmitDisabled ? onSubmitClicked : undefined}
          onKeyDown={!isSubmitDisabled ? handleKeyDown : undefined}
          className={classNames('group-forums-comment-composer-send-button', {
            'group-forums-comment-composer-send-button-active': !isSubmitDisabled
          })}
        />
      </div>
      {commentModeratedError && (
        <div className='group-forums-comment-composer-error'>
          <div className='group-forums-comment-composer-error-icon'>
            <span className='icon-status-alert' />
          </div>
          <div className='group-forums-comment-composer-error-message'>
            {translate('Error.CommentModerationFailed')}
          </div>
        </div>
      )}
    </div>
  );
};
export default withTranslations(CommentComposer, groupsConfig);
