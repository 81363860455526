import { Endpoints, EnvironmentUrls } from 'Roblox';

const { groupsApi } = EnvironmentUrls;

const forumsUrlPrefix = `${groupsApi}/v1/groups`;
const routerBase = '/forums';

const deepLinkBaseUrl = (groupId: number): string =>
  `${EnvironmentUrls.websiteUrl}/groups/${groupId}#!/forums`;

export enum CommentVariants {
  Post = 'post',
  Comment = 'comment',
  Reply = 'reply'
}

export default {
  urls: {
    reportAbuse(
      groupId: number,
      postId: string,
      channelId: string,
      commentId: string,
      addRedirectUrl: boolean
    ): string {
      return Endpoints.getAbsoluteUrl(
        `/abuseReport/groupforumcomment?id=${groupId}&stringId=${commentId}&conversationId=${channelId}&forumPostId=${postId}${
          addRedirectUrl ? `&RedirectUrl=${encodeURIComponent(window.location.href)}` : ''
        }`
      );
    },
    getForumsPageUrl: deepLinkBaseUrl,
    getForumCategoriesEndpoint(groupId: number, archived: boolean): string {
      return `${forumsUrlPrefix}/${groupId}/forums${archived ? '?archived=true' : ''}`;
    },
    getForumCategoryEndpoint(groupId: number, forumCategoryId: string, archived = false): string {
      return `${forumsUrlPrefix}/${groupId}/forums/${forumCategoryId}${
        archived ? '?archived=true' : ''
      }`;
    },
    getForumPostsByIdsEndpoint(groupId: number, categoryId: string, postIds: string[]): string {
      return `${forumsUrlPrefix}/${groupId}/forums/${categoryId}/posts?postIds=${postIds.join(
        ','
      )}`;
    },
    getForumPostsEndpoint(
      groupId: number,
      categoryId: string,
      includeCommentCount: boolean
    ): string {
      return `${forumsUrlPrefix}/${groupId}/forums/${categoryId}/posts${
        includeCommentCount ? '?includeCommentCount=true' : ''
      }`;
    },
    getForumCommentsEndpoint(groupId: number, categoryId: string, postId: string): string {
      return `${forumsUrlPrefix}/${groupId}/forums/${categoryId}/posts/${postId}/comments`;
    },
    getForumCommentReactionsEndpoint(
      groupId: number,
      channelId: string,
      commentId: string,
      emoteId: string
    ): string {
      return `${forumsUrlPrefix}/${groupId}/forums/channels/${channelId}/comments/${commentId}/reactions/${emoteId}`;
    },
    getArchiveForumCategoryEndpoint(groupId: number, forumCategoryId: string): string {
      return `${forumsUrlPrefix}/${groupId}/forums/${forumCategoryId}/archive`;
    },
    getConfigureGroupRolesSectionUrl(groupId: number): string {
      return `${EnvironmentUrls.websiteUrl}/groups/configure?id=${groupId}#!/roles`;
    },
    getForumPinnedPostsEndpoint(
      groupId: number,
      categoryId: string,
      includeCommentCount: boolean
    ): string {
      return `${forumsUrlPrefix}/${groupId}/forums/${categoryId}/posts/pinned${
        includeCommentCount ? '?includeCommentCount=true' : ''
      }`;
    },
    toggleForumPostPinEndpoint(groupId: number, categoryId: string, postId: string): string {
      return `${forumsUrlPrefix}/${groupId}/forums/${categoryId}/posts/${postId}/pin`;
    },
    toggleForumPostLockEndpoint(groupId: number, categoryId: string, postId: string): string {
      return `${forumsUrlPrefix}/${groupId}/forums/${categoryId}/posts/${postId}/lock`;
    },
    deleteForumPostEndpoint(groupId: number, categoryId: string, postId: string): string {
      return `${forumsUrlPrefix}/${groupId}/forums/${categoryId}/posts/${postId}`;
    },
    forumCommentEndpoint(
      groupId: number,
      categoryId: string,
      postId: string,
      commentId: string
    ): string {
      return `${forumsUrlPrefix}/${groupId}/forums/${categoryId}/posts/${postId}/comments/${commentId}`;
    },
    markForumPostAsReadEndpoint(
      groupId: number,
      categoryId: string,
      postId: string,
      commentId: string
    ): string {
      return `${forumsUrlPrefix}/${groupId}/forums/${categoryId}/posts/${postId}/comments/${commentId}/read`;
    },
    getPostNotificationPreferenceEndpoint(
      groupId: number,
      categoryId: string,
      postId: string
    ): string {
      return `${forumsUrlPrefix}/${groupId}/forums/${categoryId}/posts/${postId}/notifications`;
    },
    getCommentNotificationPreferenceEndpoint(
      groupId: number,
      categoryId: string,
      postId: string,
      commentId: string
    ): string {
      return `${forumsUrlPrefix}/${groupId}/forums/${categoryId}/posts/${postId}/comments/${commentId}/notifications`;
    }
  },
  limits: {
    categoryNameMinLength: 1,
    categoryNameMaxLength: 25,
    categoryDescriptionMinLength: 1,
    categoryDescriptionMaxLength: 130,
    maxNumberOfCategories: 10,
    minNumberOfCategories: 1,
    postTitleMaxLength: 100,
    postContentMaxLength: 1000
  },
  pageCounts: {
    postsPerPage: 10,
    commentsPerPage: 10
  },
  router: {
    categoryRoute: `${routerBase}/:categoryId`,
    postRoute: `${routerBase}/:categoryId/post/:postId`,
    postEditRoute: `${routerBase}/:categoryId/post/:postId/edit`,
    postCommentRoute: `${routerBase}/:categoryId/post/:postId/comment/:commentId`,
    postCreateRoute: `${routerBase}/:categoryId/post/create`,
    defaultRoute: routerBase,
    getCategoryRoute(categoryId: string): string {
      return `${routerBase}/${categoryId}`;
    },
    getPostRoute(categoryId: string, postId: string): string {
      return `${routerBase}/${categoryId}/post/${postId}`;
    },
    getPostEditRoute(categoryId: string, postId: string): string {
      return `${routerBase}/${categoryId}/post/${postId}/edit`;
    },
    getPostCreateRoute(categoryId: string): string {
      return `${routerBase}/${categoryId}/post/create`;
    }
  },
  noOpFunctionRef: (): void => {
    /* do nothing */
  },
  deepLinks: {
    groupForumPostUrl(groupId: number, categoryId: string, postId: string): string {
      return `${deepLinkBaseUrl(groupId)}/${categoryId}/post/${postId}`;
    },
    groupForumCommentUrl(
      groupId: number,
      categoryId: string,
      postId: string,
      commentId: string
    ): string {
      return `${deepLinkBaseUrl(groupId)}/${categoryId}/post/${postId}/comment/${commentId}`;
    }
  },
  CommentVariants
};
