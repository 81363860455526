import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { useSystemFeedback, Loading, IconButton } from 'react-style-guide';
import { groupsConfig } from '../translation.config';
import { Group, GroupPermissions } from '../../shared/types';
import { ForumCategory } from '../types';
import forumsService from '../services/forumsService';
import CreateOrUpdateForumCategoryDialog from './dialogs/CreateOrUpdateForumCategoryDialog';
import GroupForumsCategoryConfigSection from './GroupForumsCategoryConfigSection';
import GroupForumsUpsell from './GroupForumsUpsell';
import groupForumsConstants from '../constants/groupForumsConstants';

export type GroupForumsConfigSectionProps = {
  group: Group;
  permissions: GroupPermissions;
} & WithTranslationsProps;

const GroupForumsConfigSection = ({
  group,
  permissions,
  translate
}: GroupForumsConfigSectionProps): JSX.Element | null => {
  const { SystemFeedbackComponent, systemFeedbackService } = useSystemFeedback();

  const [isCreateForumCategoryDialogVisible, setIsCreateForumCategoryDialogVisible] = useState(
    false
  );
  const [forumCategories, setForumCategories] = useState<ForumCategory[]>([]);
  const [archivedForumCategories, setArchivedForumCategories] = useState<ForumCategory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [networkError, setNetworkError] = useState<boolean>(false);
  const [showRoles] = useState<boolean>(permissions.groupMembershipPermissions.changeRank);

  const fetchForumCategories = useCallback(async () => {
    if (!group?.id) {
      return;
    }
    try {
      setIsLoading(true);
      const [getCategoriesResponse, getArchivedCategoriesResponse] = await Promise.all([
        forumsService.getGroupForumCategories(group.id, false),
        forumsService.getGroupForumCategories(group.id, true)
      ]);
      setForumCategories(getCategoriesResponse.data);
      setArchivedForumCategories(getArchivedCategoriesResponse.data);
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
      setNetworkError(true);
    } finally {
      setIsLoading(false);
    }
  }, [group.id, translate, setIsLoading, setForumCategories, systemFeedbackService]);

  const refetchForumCategories = useCallback(async () => {
    await fetchForumCategories();
  }, [fetchForumCategories]);

  const upsellFinished = useCallback(async () => {
    await fetchForumCategories();
  }, [fetchForumCategories]);

  const canAddForumCategory = useMemo(() => {
    return (
      forumCategories.length + archivedForumCategories.length <
      groupForumsConstants.limits.maxNumberOfCategories
    );
  }, [forumCategories, archivedForumCategories]);

  const canDeleteForumCategory = useMemo(() => {
    // There should always be atleast 1 visible category
    return forumCategories.length > groupForumsConstants.limits.minNumberOfCategories;
  }, [forumCategories]);

  const canArchiveForumCategory = useMemo(() => {
    // There should always be atleast 1 visible category
    return forumCategories.length > groupForumsConstants.limits.minNumberOfCategories;
  }, [forumCategories]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchForumCategories();
  }, [fetchForumCategories]);

  const showCreateForumCategoryDialog = useCallback(() => {
    setIsCreateForumCategoryDialogVisible(true);
  }, [setIsCreateForumCategoryDialogVisible]);

  const hideCreateForumCategoryDialog = useCallback(() => {
    setIsCreateForumCategoryDialogVisible(false);
  }, [setIsCreateForumCategoryDialogVisible]);

  if (networkError) {
    // Don't show the upsell if there was a network error, instead just show nothing
    // If a network error occured then the user will get a toast saying to try again
    return null;
  }
  if (forumCategories.length === 0 && archivedForumCategories.length === 0) {
    if (isLoading) {
      return <Loading />;
    }
    return (
      <div className='section-content remove-panel'>
        <GroupForumsUpsell group={group} onFinished={upsellFinished} />
        <SystemFeedbackComponent />
      </div>
    );
  }
  return (
    <div className='section-content remove-panel group-forums-config-section'>
      {showRoles && (
        <div className='group-forums-config-category border-bottom'>
          <div className='group-forums-config-category-name font-bold'>
            {translate('Heading.Roles')}
          </div>
          <a
            className='group-forums-config-category-icons'
            href={groupForumsConstants.urls.getConfigureGroupRolesSectionUrl(group.id)}>
            <IconButton
              iconName='next'
              size={IconButton.sizes.small}
              onClick={groupForumsConstants.noOpFunctionRef}
            />
          </a>
        </div>
      )}
      <h2>{translate('Heading.Categories')}</h2>
      <button
        disabled={!canAddForumCategory}
        className='btn-primary-md btn-min-width group-forums-config-add-category-button'
        type='button'
        onClick={showCreateForumCategoryDialog}>
        <span className='icon-default-generic icon-add-category' />
        <span className='font-bold'>{translate('Action.AddForumCategory')}</span>
      </button>
      {!canAddForumCategory && (
        <span className='text-secondary'>
          {translate('Description.MaxNumberOfCategoriesReached', {
            maxNumberOfCategories: groupForumsConstants.limits.maxNumberOfCategories
          })}
        </span>
      )}
      {isCreateForumCategoryDialogVisible && (
        <CreateOrUpdateForumCategoryDialog
          groupId={group.id}
          forumCategory={null}
          onSuccess={refetchForumCategories}
          onClose={hideCreateForumCategoryDialog}
        />
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <div className='group-forums-config-categories'>
          <div className='group-forums-config-categories-list'>
            {forumCategories.map(forumCategory => (
              <GroupForumsCategoryConfigSection
                key={forumCategory.id}
                group={group}
                forumCategory={forumCategory}
                isDeleteDisabled={!canDeleteForumCategory}
                isArchiveDisabled={!canArchiveForumCategory}
                refetchForumCategories={refetchForumCategories}
              />
            ))}
          </div>
          {archivedForumCategories.length > 0 && (
            <div className='font-bold'>{translate('Heading.ArchivedCategories')}</div>
          )}
          <div className='group-forums-config-categories-list'>
            {archivedForumCategories.map(forumCategory => (
              <GroupForumsCategoryConfigSection
                key={forumCategory.id}
                group={group}
                forumCategory={forumCategory}
                isDeleteDisabled={false}
                isArchiveDisabled={false}
                refetchForumCategories={refetchForumCategories}
              />
            ))}
          </div>
        </div>
      )}
      <SystemFeedbackComponent />
    </div>
  );
};

export default withTranslations(GroupForumsConfigSection, groupsConfig);
