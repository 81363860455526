import React, { useEffect } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';

import { createModal, useSystemFeedback } from 'react-style-guide';
import userBlockingService from '../../services/userBlockingService';
import useForumsUIContext from '../../hooks/useForumsUIContext';
import { UIActionType } from '../../store/forumsUIAction';
import { groupsConfig } from '../../translation.config';
import usePostContext from '../../hooks/usePostContext';
import { ActionType } from '../../store/postAction';

const BlockUserModal = ({ translate }: WithTranslationsProps): JSX.Element | null => {
  const { state, dispatch } = useForumsUIContext();
  const { dispatch: postDispatch } = usePostContext();
  const [BlockModal, modalService] = createModal();
  const { systemFeedbackService } = useSystemFeedback();
  useEffect(() => {
    if (state?.blockUserDialogContext?.userId) {
      modalService.open();
    } else {
      modalService.close();
    }
  }, [modalService, state?.blockUserDialogContext?.userId]);

  if (!state?.blockUserDialogContext?.userId) {
    return null;
  }

  const blockUser = async () => {
    if (state?.blockUserDialogContext?.userId) {
      try {
        await userBlockingService.blockUser(state.blockUserDialogContext.userId);
        // dispatch an add for blocking a user.
        systemFeedbackService.success(translate('Message.BlockUserSuccess'));
        postDispatch({
          type: ActionType.BLOCK_USER,
          payload: { userId: state.blockUserDialogContext.userId }
        });
      } catch (e) {
        systemFeedbackService.warning(translate('NetworkError'));
      }

      dispatch({ type: UIActionType.CLOSE_BLOCK_DIALOG });
    }
  };

  const hideUserBlockModal = () => {
    dispatch({ type: UIActionType.CLOSE_BLOCK_DIALOG });
  };

  return (
    <BlockModal
      footerText={translate('Description.BlockUserFooterDialog')}
      title={translate('Heading.BlockUserWarning')}
      onClose={hideUserBlockModal}
      body={translate('Description.BlockUserWarning')}
      actionButtonShow
      actionButtonText={translate('Action.BlockUser')}
      onAction={blockUser}
      neutralButtonText={translate('Action.Cancel')}
      onNeutral={hideUserBlockModal}
    />
  );
};

export default withTranslations(BlockUserModal, groupsConfig);
