import React, { useCallback, useEffect, useState } from 'react';
import { useSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import classNames from 'classnames';
import { ForumPost, NotificationPreferenceType } from '../types';
import { groupsConfig } from '../translation.config';
import forumsService from '../services/forumsService';
import PostPreview from '../components/PostPreview';
import PostPreviewSkeleton from '../components/skeletons/PostPreviewSkeleton';
import groupForumsConstants from '../constants/groupForumsConstants';
import InfiniteLoader from '../components/InfiniteLoader';
import useCursoredData from '../hooks/useCursoredData';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import { ComparePost } from '../utils/typeComparison';
import usePostContext from '../hooks/usePostContext';

export type PostPreviewListProps = {
  groupId: number;
  categoryId: string;
} & WithTranslationsProps;

const PostPreviewList = ({ groupId, categoryId, translate }: PostPreviewListProps): JSX.Element => {
  const { systemFeedbackService } = useSystemFeedback();
  const { canCreatePost } = useForumPermissions();
  const [pinnedCategoryPosts, setPinnedCategoryPosts] = useState<ForumPost[]>([]);
  const [isLoadingPinnedPosts, setIsLoadingPinnedPosts] = useState<boolean>(true);
  const { state } = usePostContext();

  const fetchPinnedCategoryPosts = useCallback(async () => {
    try {
      setIsLoadingPinnedPosts(true);
      const response = await forumsService.getGroupForumPinnedPosts(groupId, categoryId);
      setPinnedCategoryPosts(response.data);
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    } finally {
      setIsLoadingPinnedPosts(false);
    }
  }, [groupId, categoryId, systemFeedbackService, translate]);

  const fetchCategoryPosts = useCallback(
    async (cursor: string | null) => {
      const response = await forumsService.getGroupForumPosts(
        groupId,
        categoryId,
        groupForumsConstants.pageCounts.postsPerPage,
        cursor
      );

      // filter pinned posts
      response.data = response.data.filter(post => !post.isPinned);

      return response;
    },
    [categoryId, groupId]
  );

  const {
    items: categoryPosts,
    isLoadingInitialItems: isLoading,
    isFetchingNextPage,
    error: errorLoadingPosts,
    refetch: refetchCategoryPosts,
    fetchMore: fetchNextPostPage,
    updateItem: updatePost
  } = useCursoredData<ForumPost>({
    fetchItems: fetchCategoryPosts,
    initialCursor: null,
    compareFn: ComparePost
  });

  const refetchAllPosts = useCallback(() => {
    // eslint-disable-next-line no-void
    void refetchCategoryPosts();
    // eslint-disable-next-line no-void
    void fetchPinnedCategoryPosts();
  }, [fetchPinnedCategoryPosts, refetchCategoryPosts]);

  const togglePostNotifications = useCallback(
    async (post: ForumPost) => {
      try {
        const newIsSubscribed = post.notificationPreference === NotificationPreferenceType.None;
        await forumsService.togglePostNotificationSubscription(
          groupId,
          categoryId,
          post.id,
          newIsSubscribed
        );
        updatePost({
          ...post,
          notificationPreference: newIsSubscribed
            ? NotificationPreferenceType.All
            : NotificationPreferenceType.None
        });
        systemFeedbackService.success(translate('Message.NotificationPreferenceUpdated'));
      } catch {
        systemFeedbackService.warning(translate('NetworkError'));
      }
    },
    [groupId, categoryId, systemFeedbackService, updatePost, translate]
  );

  // TODO: uncomment when notifications backend is up and running
  const fetchPostNotificationPreference = useCallback(
    async (post: ForumPost) => {
      const result = await forumsService.getPostNotificationPreference(
        groupId,
        categoryId,
        post.id
      );
      const updatedPost = { ...post, notificationPreference: result.preference };
      updatePost(updatedPost);
    },
    [groupId, categoryId, updatePost]
  );

  useEffect(() => {
    if (!errorLoadingPosts) return;
    systemFeedbackService.warning(translate('NetworkError'));
  }, [errorLoadingPosts, systemFeedbackService, translate]);

  useEffect(() => {
    if (!categoryId) return;
    refetchAllPosts();
  }, [categoryId, refetchAllPosts]);

  if (errorLoadingPosts) {
    return (
      <div className='group-forums-posts-list-error section-content-off'>
        <span className='icon-status-alert' />
        <h2>{translate('Error.LoadCategoryTitle')}</h2>
        <span className='group-forums-posts-list-error-subtitle'>
          {translate('Error.ReloadingSubtitle')}
        </span>
        <button type='button' className='btn-primary-md' onClick={refetchAllPosts}>
          {translate('Action.RetryLoadingPosts')}
        </button>
      </div>
    );
  }

  if (isLoading || isLoadingPinnedPosts) {
    return (
      <div className='group-forums-posts-list'>
        <PostPreviewSkeleton />
        <PostPreviewSkeleton />
        <PostPreviewSkeleton />
        <PostPreviewSkeleton />
      </div>
    );
  }

  if (!categoryPosts.length && !pinnedCategoryPosts.length) {
    return (
      <div className='group-forums-posts-list section-content-off group-forums-posts-list-no-post'>
        <span className='chat-side-icon' />
        <h2>{translate('Label.NoPostsFoundHeader')}</h2>
        <span>{translate('Label.NoPostsFoundText')}</span>
      </div>
    );
  }

  return (
    <div
      className={classNames('group-forums-posts-list', {
        'group-forums-posts-list-with-footer': canCreatePost
      })}>
      <div className='group-forums-post-list-content'>
        {pinnedCategoryPosts.map(post => {
          return (
            <PostPreview
              showPinned
              key={`${post.id}_pinned`}
              post={post}
              refetchPosts={refetchAllPosts}
              /* onMenuOpened={() => fetchPostNotificationPreference(post)} */
              togglePostNotifications={() => togglePostNotifications(post)}
            />
          );
        })}
        {categoryPosts.map(post => {
          return (
            <PostPreview
              showPinned={false}
              key={post.id}
              post={post}
              refetchPosts={refetchAllPosts}
              /* onMenuOpened={() => fetchPostNotificationPreference(post)} */
              togglePostNotifications={() => togglePostNotifications(post)}
            />
          );
        })}
        <InfiniteLoader onLoadMore={fetchNextPostPage} viewingThreshold={1} />
        {isFetchingNextPage && <div className='spinner spinner-default spinner-infinite-scroll' />}
      </div>
    </div>
  );
};

export default withTranslations(PostPreviewList, groupsConfig);
