import { useContext } from 'react';
import { ForumsUIContext, ForumsUIContextType } from '../store/forumsUIContextProvider';

const useForumsUIContext: () => ForumsUIContextType = () => {
  const context = useContext(ForumsUIContext);
  if (context === null) {
    throw new Error('UIContext was not provided in the current scope');
  }

  return context;
};

export default useForumsUIContext;
