export enum UIActionType {
  OPEN_BLOCK_DIALOG,
  CLOSE_BLOCK_DIALOG
}

export type UIAction =
  | {
      type: UIActionType.OPEN_BLOCK_DIALOG;
      payload: { userId: number };
    }
  | { type: UIActionType.CLOSE_BLOCK_DIALOG };
