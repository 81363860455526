import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupsConfig } from '../translation.config';
import { CommentVariants } from '../constants/groupForumsConstants';
import CommentSkeleton from '../components/skeletons/CommentSkeleton';
import InfiniteLoader from '../components/InfiniteLoader';
import Comment from '../components/Comment';
import { usePost } from '../contexts/PostContext';
import usePostContext from '../hooks/usePostContext';
import useViewportSize from '../../shared/hooks/useViewportSize';

const CommentsSection = ({ translate }: WithTranslationsProps): JSX.Element => {
  const {
    refetchComments,
    comments,
    fetchNextCommentsPage,
    fetchPreviousCommentsPage,
    isFetchingNextCommentsPage,
    isFetchingPreviousCommentsPage,
    isLoadingComments,
    replyCommentHighlight,
    post,
    errorLoadingComments,
    initialCommentId,
    commentScrollContainerRef,
    mobileCommentScrollContainerRef
  } = usePost();
  const { state } = usePostContext();

  const { isSmallViewport } = useViewportSize();

  const initialScrollDone = useRef<boolean>(false);
  const previousScrollHeightRef = useRef<number>(0);

  const haveMorePreviousCommentsToLoad = useMemo(() => {
    if (!post) return false;
    const firstCommentId = post.firstComment.id;
    return !comments.some(comment => comment.id === firstCommentId);
  }, [comments, post]);

  const loadPreviousComments = useCallback(() => {
    if (haveMorePreviousCommentsToLoad) {
      fetchPreviousCommentsPage();
    }
  }, [fetchPreviousCommentsPage, haveMorePreviousCommentsToLoad]);

  const handleTargetCommentRef = useCallback((commentRef: HTMLDivElement) => {
    // Scroll to the target comment when it is loaded
    if (commentRef && !initialScrollDone.current) {
      commentRef.scrollIntoView({ behavior: 'auto', block: 'start' });
      initialScrollDone.current = true;
    }
  }, []);

  useEffect(() => {
    const scrollRef = isSmallViewport ? mobileCommentScrollContainerRef : commentScrollContainerRef;
    if (!scrollRef.current) return;
    if (isFetchingPreviousCommentsPage) {
      // Save current scroll height when we start loading previous comments
      previousScrollHeightRef.current = scrollRef.current.scrollHeight;
    } else if (!isFetchingPreviousCommentsPage && previousScrollHeightRef.current > 0) {
      // Calculate the difference in scroll height after loading previous comments
      const newScrollHeight = scrollRef.current.scrollHeight;
      const scrollDiff = newScrollHeight - previousScrollHeightRef.current;

      // Scroll to the same position as before loading previous comments
      scrollRef.current.scrollTop = scrollDiff;
      previousScrollHeightRef.current = 0;
    }
  }, [
    commentScrollContainerRef,
    isFetchingPreviousCommentsPage,
    isSmallViewport,
    mobileCommentScrollContainerRef
  ]);

  if (errorLoadingComments) {
    return (
      <div className='group-forums-comments-section-error'>
        <div className='group-forums-categories-error section-content-off'>
          <span className='icon-status-alert' />
          <h2>{translate('Error.LoadCommentsTitle')}</h2>
          <span className='group-forums-categories-error-subtitle'>
            {translate('Error.ReloadingSubtitle')}
          </span>
          <button type='button' className='btn-primary-md' onClick={refetchComments}>
            {translate('Action.RetryLoadingComments')}
          </button>
        </div>
      </div>
    );
  }

  if (isLoadingComments || !post) {
    return (
      <div className='group-forums-comments-section group-forums-comments-section-loading'>
        <CommentSkeleton />
        <CommentSkeleton />
        <CommentSkeleton />
      </div>
    );
  }

  if (comments.length <= 1) {
    return (
      <div className='group-forums-comments-section groups-forums-comments-no-comments section-content-off'>
        <span className='chat-side-icon' />
        <h2>{translate('Label.NoCommentsFoundHeader')}</h2>
        <span>{translate('Label.NoCommentsFoundText')}</span>
      </div>
    );
  }

  return (
    <div className='group-forums-comments-section'>
      <InfiniteLoader onLoadMore={loadPreviousComments} viewingThreshold={1} />
      {isFetchingPreviousCommentsPage && (
        <div className='group-forums-comments-section-spinner spinner spinner-default spinner-infinite-scroll' />
      )}
      {comments.map(comment => {
        if (comment.id === post.firstComment.id) {
          return null;
        }
        if (state.blockedUserList.length > 0 && state.blockedUserList.includes(comment.createdBy)) {
          return null;
        }
        return (
          <div
            key={comment.id}
            ref={
              initialCommentId && comment.id === initialCommentId ? handleTargetCommentRef : null
            }>
            <Comment
              key={comment.id}
              id={comment.id}
              createdBy={comment.createdBy}
              creatorDisplayName={comment.creatorDisplayName}
              createdAt={comment.createdAt}
              content={comment.content.plainText}
              threadId={comment.threadId}
              channelId={comment.parentId}
              variant={CommentVariants.Comment}
              isActive={replyCommentHighlight === comment.id}
              replies={comment.replies}
              reactions={comment.reactions}
            />
          </div>
        );
      })}
      <InfiniteLoader onLoadMore={fetchNextCommentsPage} viewingThreshold={1} />
      {isFetchingNextCommentsPage && (
        <div className='group-forums-comments-section-spinner spinner spinner-default spinner-infinite-scroll' />
      )}
    </div>
  );
};
export default withTranslations(CommentsSection, groupsConfig);
