import { logGroupPageClickEvent } from '../../shared/utils/logging';
import { EventContext } from '../constants/eventConstants';

// eslint-disable-next-line import/prefer-default-export
export const logGroupForumsClickEvent = ({
  groupId,
  clickTargetType,
  clickTargetId
}: {
  groupId: number;
  clickTargetType: string;
  clickTargetId?: string;
}): void => {
  return logGroupPageClickEvent({
    groupId,
    clickTargetType,
    clickTargetId,
    context: EventContext.GroupForums
  });
};
