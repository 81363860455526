import { useContext } from 'react';
import { PostContext, TPostContext } from '../store/postContextProvider';

const usePostContext: () => TPostContext = () => {
  const context = useContext(PostContext);
  if (context === null) {
    throw new Error('PostContext was not provided in the current scope');
  }

  return context;
};

export default usePostContext;
