import { EnvironmentUrls } from 'Roblox';

export enum StyleSize {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg'
}

export default {
  urls: {
    getUsersInfoURL: `${EnvironmentUrls.usersApi}/v1/users`
  },
  StyleSize
};
