import React, { useCallback, useState, useMemo } from 'react';
import { Modal, useSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupsConfig } from '../../translation.config';
import forumsService from '../../services/forumsService';
import { ForumCategory } from '../../types';
import groupForumsConstants from '../../constants/groupForumsConstants';
import { GetDeleteForumCategoryNameValidationErrorKey } from '../../utils/groupForumsValidation';
import { logGroupForumsClickEvent } from '../../utils/logging';

type Props = {
  groupId: number;
  forumCategory: ForumCategory;
  onSuccess: () => void;
  onClose: () => void;
} & WithTranslationsProps;

const DeleteForumCategoryDialog = ({
  groupId,
  forumCategory,
  onSuccess,
  onClose,
  translate
}: Props): JSX.Element => {
  const { systemFeedbackService } = useSystemFeedback();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const archived = useMemo(() => {
    return forumCategory.archivedBy != null;
  }, [forumCategory]);

  const onDeleteForumCategory = useCallback(async () => {
    try {
      setIsLoading(true);
      await forumsService.deleteGroupForumCategory(groupId, forumCategory.id, archived);
      onSuccess();
      onClose();
      logGroupForumsClickEvent({
        groupId,
        clickTargetType: 'deleteForumCategory',
        clickTargetId: forumCategory.id
      });
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    } finally {
      setIsLoading(false);
    }
  }, [
    onSuccess,
    onClose,
    groupId,
    forumCategory.id,
    translate,
    systemFeedbackService,
    setIsLoading,
    archived
  ]);

  const deleteDisabled = useMemo(() => {
    return isLoading;
  }, [isLoading]);

  return (
    <Modal className='delete-forum-category-dialog' show size='md' backdrop='static'>
      <Modal.Header title={translate('Action.DeleteForumCategory')} onClose={onClose} />
      <Modal.Body>
        <div className='group-forums-config-dialog-input'>
          {translate('Description.DeleteForumCategory', {
            forumCategoryName: forumCategory.name
          })}
        </div>
        <div className='group-forums-config-dialog-button-wrapper'>
          <button
            disabled={deleteDisabled}
            type='button'
            className='btn-alert-md btn-min-width group-forums-config-dialog-button'
            onClick={onDeleteForumCategory}>
            {translate('Action.Delete')}
          </button>
          <button
            type='button'
            className='btn-secondary-md btn-min-width group-forums-config-dialog-button'
            onClick={onClose}>
            {translate('Action.Cancel')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslations(DeleteForumCategoryDialog, groupsConfig);
