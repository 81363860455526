import React, { ReactChild, ReactElement, createContext, useReducer } from 'react';
import postReducer from '../reducers/postReducer';
import { Action } from './postAction';

export type PostStateType = {
  blockedUserList: number[];
};

export type TPostContext = {
  state: PostStateType;
  dispatch: React.Dispatch<Action>;
};

const initialPostState: PostStateType = {
  blockedUserList: []
};

export const PostContext = createContext<TPostContext>({
  state: initialPostState,
  dispatch: () => true
});

type TPostContextProviderProps = {
  children: ReactChild;
};

export const PostContextProvider = ({ children }: TPostContextProviderProps): ReactElement => {
  const [state, dispatch] = useReducer(postReducer, initialPostState);

  return <PostContext.Provider value={{ state, dispatch }}>{children}</PostContext.Provider>;
};
