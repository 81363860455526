import React, { useCallback, useMemo } from 'react';
import { Popover, useSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { useHistory } from 'react-router-dom';
import { groupsConfig } from '../translation.config';
import groupForumsConstants from '../constants/groupForumsConstants';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import { ForumPost, NotificationPreferenceType } from '../types';
import DropdownMenuItem from './DropdownMenuItem';
import forumsService from '../services/forumsService';
import useForumsUIContext from '../hooks/useForumsUIContext';
import { UIActionType } from '../store/forumsUIAction';

export type PostMenuProps = {
  post: ForumPost;
  onRefetchPosts?: () => void;
  onDelete?: () => void;
  onSubscribe: () => void;
  button: JSX.Element;
} & WithTranslationsProps;

const PostMenu = ({
  post,
  onRefetchPosts,
  onDelete,
  onSubscribe,
  button,
  translate
}: PostMenuProps): JSX.Element => {
  const history = useHistory();
  const {
    canEditPost,
    canDeletePost,
    canLockPost,
    canPinPost,
    canSubscribe,
    canBlockUser
  } = useForumPermissions();
  const { systemFeedbackService } = useSystemFeedback();
  const { dispatch } = useForumsUIContext();

  const showEditPost = useMemo(() => canEditPost(post.createdBy), [canEditPost, post.createdBy]);
  const showDeletePost = useMemo(() => canDeletePost(post.createdBy), [
    canDeletePost,
    post.createdBy
  ]);

  const showBlockUser = useMemo(() => canBlockUser(post.createdBy), [canBlockUser, post.createdBy]);

  const handleEditPost = () => {
    history.push(groupForumsConstants.router.getPostEditRoute(post.categoryId, post.id));
  };

  const handleTogglePin = useCallback(async () => {
    try {
      await forumsService.toggleGroupForumPostPin(
        post.groupId,
        post.categoryId,
        post.id,
        !post.isPinned
      );
      onRefetchPosts?.();
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    }
  }, [
    post.groupId,
    post.categoryId,
    post.id,
    post.isPinned,
    onRefetchPosts,
    systemFeedbackService,
    translate
  ]);

  const handleToggleLock = useCallback(async () => {
    try {
      await forumsService.toggleGroupForumPostLock(
        post.groupId,
        post.categoryId,
        post.id,
        !post.isLocked
      );
      onRefetchPosts?.();
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    }
  }, [
    post.groupId,
    post.categoryId,
    post.id,
    post.isLocked,
    onRefetchPosts,
    systemFeedbackService,
    translate
  ]);

  const handleDeletePost = useCallback(async () => {
    try {
      await forumsService.deleteGroupForumPost(post.groupId, post.categoryId, post.id);
      onDelete?.();
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    }
  }, [post.groupId, post.categoryId, post.id, onDelete, systemFeedbackService, translate]);

  const handleCopyLink = useCallback(async () => {
    if (navigator.clipboard) {
      try {
        const postUrl = groupForumsConstants.deepLinks.groupForumPostUrl(
          post.groupId,
          post.categoryId,
          post.id
        );
        await navigator.clipboard.writeText(postUrl);
        systemFeedbackService.success(translate('Label.LinkCopied'));
      } catch {
        systemFeedbackService.warning(translate('Error.CopyLink'));
      }
    }
  }, [post.categoryId, post.groupId, post.id, systemFeedbackService, translate]);

  const handleReportPost = useCallback(() => {
    const reportUrl = groupForumsConstants.urls.reportAbuse(
      post.groupId,
      post.id,
      post.id,
      post.firstComment.id,
      true
    );
    window.location.href = reportUrl;
  }, [post.firstComment.id, post.groupId, post.id]);

  const onBlockUser = () => {
    dispatch({
      type: UIActionType.OPEN_BLOCK_DIALOG,
      payload: {
        userId: post.createdBy
      }
    });
  };

  const isSubscribed = post.notificationPreference === NotificationPreferenceType.All;

  return (
    <Popover
      id='group-forums-post-dropdown-menu'
      button={button}
      trigger='click'
      placement='bottom'>
      <ul className='dropdown-menu' role='menu'>
        {showEditPost && <DropdownMenuItem translateKey='Label.EditPost' action={handleEditPost} />}
        {canPinPost && (
          <DropdownMenuItem
            translateKey={post.isPinned ? 'Label.UnpinPost' : 'Label.PinPost'}
            action={handleTogglePin}
          />
        )}
        {canLockPost && (
          <DropdownMenuItem
            translateKey={post.isLocked ? 'Label.UnlockPost' : 'Label.LockPost'}
            action={handleToggleLock}
          />
        )}
        {showDeletePost && (
          <DropdownMenuItem translateKey='Label.DeletePost' action={handleDeletePost} />
        )}
        {navigator.clipboard && (
          <DropdownMenuItem translateKey='Label.CopyLink' action={handleCopyLink} />
        )}
        {canSubscribe && (
          <DropdownMenuItem
            translateKey={isSubscribed ? 'Label.UnsubscribeFromPost' : 'Label.SubscribeToPost'}
            action={onSubscribe}
            disabled={post.notificationPreference === undefined}
          />
        )}
        {showBlockUser && <DropdownMenuItem translateKey='Label.BlockUser' action={onBlockUser} />}
        <DropdownMenuItem translateKey='Label.ReportAbuse' action={handleReportPost} />
      </ul>
    </Popover>
  );
};

export default withTranslations(PostMenu, groupsConfig);
