import React from 'react';
import { Button } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupsConfig } from '../translation.config';

export type DropdownMenuItemProps = {
  translateKey: string;
  action: () => void;
  disabled?: boolean;
} & WithTranslationsProps;

const noOp = () => null;

const DropdownMenuItem = ({
  translateKey,
  action,
  disabled,
  translate
}: DropdownMenuItemProps): JSX.Element => {
  return (
    <li>
      <Button
        variant={Button.variants.secondary}
        onClick={disabled ? noOp : action}
        isDisabled={disabled}>
        {translate(translateKey)}
      </Button>
    </li>
  );
};

export default withTranslations(DropdownMenuItem, groupsConfig);
