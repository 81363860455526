import { useCallback, useState, useEffect } from 'react';
import forumsService from '../services/forumsService';
import { ForumPost } from '../types';

type ForumPostReturn = {
  isLoading: boolean;
  errorKey: string | null;
  forumPost?: ForumPost;
};

const useForumPost = (groupId: number, categoryId: string, postId?: string): ForumPostReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const [forumPost, setForumPost] = useState<ForumPost>();

  const fetchForumPost = useCallback(async () => {
    if (!groupId || !categoryId || !postId) return;
    try {
      setIsLoading(true);
      setErrorKey(null);
      const response = await forumsService.getGroupForumPostsByIds(groupId, categoryId, [postId]);
      setForumPost(response.data[0]);
    } catch {
      setErrorKey('REPLACEME');
    } finally {
      setIsLoading(false);
    }
  }, [groupId, categoryId, postId]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchForumPost();
  }, [fetchForumPost]);

  return {
    isLoading,
    errorKey,
    forumPost
  };
};

export default useForumPost;
