import React, { useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { abbreviateNumber } from 'core-utilities';
import { useHistory } from 'react-router-dom';
import { ForumPost } from '../types';
import { groupsConfig } from '../translation.config';
import UserDisplay from './UserDisplay';
import PostPreviewReactions from './PostPreviewReactions';
import groupForumsConstants from '../constants/groupForumsConstants';
import PostMenu from './PostMenu';
import usePostContext from '../hooks/usePostContext';
import { logGroupForumsClickEvent } from '../utils/logging';

export type PostPreviewProps = {
  showPinned: boolean;
  post: ForumPost;
  refetchPosts: () => void;
  onMenuOpened?: () => void;
  togglePostNotifications: (postId: string) => void;
} & WithTranslationsProps;

const PostPreview = ({
  post,
  showPinned,
  refetchPosts,
  onMenuOpened,
  togglePostNotifications,
  translate
}: PostPreviewProps): JSX.Element | null => {
  const history = useHistory();

  const { state } = usePostContext();

  const onPressPost = () => {
    history.push(groupForumsConstants.router.getPostRoute(post.categoryId, post.id));
    logGroupForumsClickEvent({
      groupId: post.groupId,
      clickTargetType: 'openPost',
      clickTargetId: post.id
    });
  };

  // Handle keyboard interactions
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onPressPost();
    }
  };

  const handleMenuOpened = useCallback(() => {
    onMenuOpened?.();
    logGroupForumsClickEvent({
      groupId: post.groupId,
      clickTargetType: 'openPostMenu',
      clickTargetId: post.id
    });
  }, [post.groupId, post.id, onMenuOpened]);

  const togglePostNotificationsCallback = useCallback(() => {
    togglePostNotifications(post.id);
  }, [post.id, togglePostNotifications]);

  const replyCount = useMemo(() => {
    if (post.commentCount <= 0) return 0;
    return post.commentCount - 1; // We don't count the first comment as a reply
  }, [post.commentCount]);

  if (state.blockedUserList.length > 0 && state.blockedUserList.includes(post.createdBy)) {
    return null;
  }

  return (
    <div className='group-forums-post-preview'>
      <div className='group-forums-post-preview-header'>
        <UserDisplay
          userId={post.createdBy}
          createdTime={post.createdAt}
          userDisplayName={post.firstComment.creatorDisplayName}
        />
        <div className='group-forums-post-preview-menu'>
          <PostMenu
            post={post}
            onRefetchPosts={refetchPosts}
            onDelete={refetchPosts}
            onSubscribe={togglePostNotificationsCallback}
            button={
              <button
                type='button'
                className='group-forums-post-preview-dropdown-menu-button btn-generic-more-sm'
                title='more'
                onClick={handleMenuOpened}>
                <span className='group-forums-post-preview-overflow-icon' />
              </button>
            }
          />
        </div>
      </div>
      <div
        role='button'
        tabIndex={0}
        className='group-forums-post-preview-content'
        onClick={onPressPost}
        onKeyDown={handleKeyDown}>
        <div className='group-forums-post-preview-title-container'>
          {post.isUnread && <div className='group-forums-post-preview-unread-status' />}
          {showPinned && post.isPinned && (
            <span className='group-forums-post-preview-pinned-status-icon' />
          )}
          {post.isLocked && <span className='group-forums-post-preview-locked-status-icon' />}
          <h2 className='group-forums-post-preview-title text-emphasis text-overflow'>
            {post.name}
          </h2>
        </div>
        <div
          className={classNames(
            'group-forums-post-preview-content-comment',
            post.isUnread ? 'font-bold text-emphasis' : 'text-default'
          )}>
          {post.firstComment.content.plainText}
        </div>
        <div className='group-forums-post-preview-meta-data'>
          <div className='group-forums-post-preview-meta-data-replies text-default'>
            <span className='group-forums-post-preview-replies-icon' />
            {abbreviateNumber.getAbbreviatedValue(replyCount)}{' '}
            {replyCount === 1 ? translate('Label.Reply') : translate('Label.Replies')}
          </div>
          <div className='group-forums-post-preview-meta-data-reactions text-default'>
            <PostPreviewReactions reactions={post.firstComment.reactions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslations(PostPreview, groupsConfig);
