import { UIAction, UIActionType } from '../store/forumsUIAction';
import { UIState } from '../store/forumsUIContextProvider';

const forumsUIReducer = (state: UIState, action: UIAction): UIState => {
  switch (action.type) {
    case UIActionType.OPEN_BLOCK_DIALOG:
      return {
        ...state,
        blockUserDialogContext: {
          userId: action.payload.userId
        }
      };
    case UIActionType.CLOSE_BLOCK_DIALOG:
      return {
        ...state,
        blockUserDialogContext: undefined
      };
    default:
      return state;
  }
};

export { forumsUIReducer as default };
