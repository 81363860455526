import React, { useCallback, useState, useMemo } from 'react';
import { Modal, useSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupsConfig } from '../../translation.config';
import forumsService from '../../services/forumsService';
import { ForumCategory } from '../../types';
import groupForumsConstants from '../../constants/groupForumsConstants';
import {
  GetForumCategoryNameValidationErrorKey,
  GetForumCategoryErrorMessageErrorKey
} from '../../utils/groupForumsValidation';
import { logGroupForumsClickEvent } from '../../utils/logging';

type CreateOrUpdateForumCategoryDialogProps = {
  groupId: number;
  forumCategory: ForumCategory | null;
  onSuccess: () => void;
  onClose: () => void;
} & WithTranslationsProps;

const CreateOrUpdateForumCategoryDialog = ({
  groupId,
  forumCategory,
  onSuccess,
  onClose,
  translate
}: CreateOrUpdateForumCategoryDialogProps): JSX.Element => {
  const { systemFeedbackService } = useSystemFeedback();

  const [name, setName] = useState(forumCategory?.name || '');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const onSave = useCallback(async () => {
    try {
      setIsLoading(true);
      if (forumCategory) {
        await forumsService.updateGroupForumCategory(groupId, forumCategory.id, name);
      } else {
        await forumsService.createGroupForumCategory(groupId, name);
      }
      onSuccess();
      onClose();
      logGroupForumsClickEvent({
        groupId,
        clickTargetType: forumCategory ? 'updateForumCategory' : 'createForumCategory'
      });
    } catch (e) {
      // get the errors message
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      const message: string | undefined = e?.data?.errors?.[0]?.message;
      if (message) {
        setErrorMessage(message);
      }
      systemFeedbackService.warning(translate('NetworkError'));
    } finally {
      setIsLoading(false);
    }
  }, [
    onSuccess,
    onClose,
    groupId,
    forumCategory,
    name,
    translate,
    systemFeedbackService,
    setIsLoading
  ]);

  const validationErrorKey = useMemo(() => {
    return (
      GetForumCategoryNameValidationErrorKey(name) ||
      GetForumCategoryErrorMessageErrorKey(errorMessage)
    );
  }, [name, errorMessage]);

  const saveDisabled = useMemo(() => {
    return isLoading || !!validationErrorKey;
  }, [validationErrorKey, isLoading]);

  const handleNameInputChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsEdited(true);
      setErrorMessage('');
      setName(event.target.value);
    },
    [setName]
  );

  const titleText = useMemo(() => {
    return translate(forumCategory ? 'Action.RenameForumCategory' : 'Action.AddForumCategory');
  }, [forumCategory, translate]);

  const primaryButtonText = useMemo(() => {
    return translate(forumCategory ? 'Action.Rename' : 'Action.Add');
  }, [forumCategory, translate]);

  return (
    <Modal className='edit-forum-category-dialog' show size='md' backdrop='static'>
      <Modal.Header title={titleText} onClose={onClose} />
      <Modal.Body>
        <div className='group-forums-config-dialog-input'>
          <label className='text-label' htmlFor='edit-forum-category-name-input'>
            {translate('Label.ForumCategoryName')}
          </label>
          <input
            id='edit-forum-category-name-input'
            type='text'
            placeholder={translate('Label.ForumCategoryNamePlaceholder')}
            minLength={groupForumsConstants.limits.categoryNameMinLength}
            maxLength={groupForumsConstants.limits.categoryNameMaxLength}
            className='form-control input-field'
            autoComplete='off'
            onChange={handleNameInputChanged}
            value={name}
          />
        </div>
        <p className='group-forums-config-form-input-error'>
          {isEdited && validationErrorKey && translate(validationErrorKey)}
        </p>
        <div className='group-forums-config-dialog-button-wrapper'>
          <button
            disabled={saveDisabled}
            type='button'
            className='btn-primary-md btn-min-width group-forums-config-dialog-button'
            onClick={onSave}>
            {primaryButtonText}
          </button>
          <button
            type='button'
            className='btn-secondary-md btn-min-width group-forums-config-dialog-button'
            onClick={onClose}>
            {translate('Action.Cancel')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslations(CreateOrUpdateForumCategoryDialog, groupsConfig);
