export enum ActionType {
  BLOCK_USER,
  CLEAR_BLOCKED_USERS
}

export type Action =
  | {
      type: ActionType.BLOCK_USER;
      payload: { userId: number };
    }
  | { type: ActionType.CLEAR_BLOCKED_USERS };
