import React from 'react';
import { Link } from 'react-style-guide';
import { Endpoints } from 'Roblox';
import { Thumbnail2d, ThumbnailAvatarHeadshotSize, ThumbnailTypes } from 'roblox-thumbnails';
import abbreviateTimeUtil from '../../shared/utils/abbreviateTime';

export type UserDisplayProps = {
  userId: number;
  userDisplayName: string;
  createdTime: Date;
};

const UserDisplay = ({
  userId,
  createdTime,
  userDisplayName
}: UserDisplayProps): JSX.Element | null => {
  const separator = '•';
  const createdDate = new Date(createdTime);
  return (
    <div className='group-forums-user-display'>
      <Link
        className='group-forums-user-display-avatar-username-link text-default'
        url={Endpoints.getAbsoluteUrl(`/users/${userId}/profile`)}>
        <Thumbnail2d
          type={ThumbnailTypes.avatarHeadshot}
          size={ThumbnailAvatarHeadshotSize.size48}
          targetId={userId}
          containerClass='group-forums-user-display-avatar-thumbnail'
          altName={userDisplayName}
        />
        {userDisplayName}
      </Link>
      <div className='user-display-time text-default'>
        &nbsp;
        {separator}
        &nbsp;
        <span className='user-display-time-full-time' title={createdDate.toLocaleString()}>
          {abbreviateTimeUtil.abbreviateTime(createdDate)}
        </span>
      </div>
    </div>
  );
};

export default UserDisplay;
