import Roblox from 'Roblox';
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { SystemFeedbackProvider } from 'react-style-guide';
import GroupForums, { GroupForumsProps } from './containers/GroupForums';
import GroupForumsConfigSection, {
  GroupForumsConfigSectionProps
} from './components/GroupForumsConfigSection';
import GroupWallDeprecationNotice, {
  GroupWallDeprecationNoticeProps
} from './components/GroupWallDeprecationNotice';
import '../../../css/groupForums/groupForums.scss';

const renderGroupForums = (container: Element, props: GroupForumsProps) => {
  unmountComponentAtNode(container); // make sure we aren't double-rendering components
  render(<GroupForums {...props} />, container);
};

const renderGroupForumsConfigSection = (
  container: Element,
  props: GroupForumsConfigSectionProps
) => {
  unmountComponentAtNode(container); // make sure we aren't double-rendering components
  render(
    <SystemFeedbackProvider>
      <GroupForumsConfigSection {...props} />
    </SystemFeedbackProvider>,
    container
  );
};

const renderGroupWallDeprecationNotice = (
  container: Element,
  props: GroupWallDeprecationNoticeProps
) => {
  unmountComponentAtNode(container); // make sure we aren't double-rendering components
  render(<GroupWallDeprecationNotice {...props} />, container);
};

const GroupForumsService = {
  renderGroupForums,
  renderGroupForumsConfigSection,
  renderGroupWallDeprecationNotice
};

Object.assign(Roblox, {
  GroupForumsService
});
