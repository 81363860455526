import React, { ReactChild, ReactElement, createContext, useReducer } from 'react';
import { UIAction } from './forumsUIAction';
import forumsUIReducer from '../reducers/forumsUIReducer';

export type UIState = {
  blockUserDialogContext?: {
    userId: number;
  };
};

export type ForumsUIContextType = {
  state: UIState;
  dispatch: React.Dispatch<UIAction>;
};

const initialUiState: UIState = {
  blockUserDialogContext: undefined
};

export const ForumsUIContext = createContext<ForumsUIContextType>({
  state: initialUiState,
  dispatch: () => true
});

type ForumsUIContextProviderProps = {
  children: ReactChild;
};

export const ForumsUIContextProvider = ({
  children
}: ForumsUIContextProviderProps): ReactElement => {
  const [state, dispatch] = useReducer(forumsUIReducer, initialUiState);

  return (
    <ForumsUIContext.Provider value={{ state, dispatch }}>{children}</ForumsUIContext.Provider>
  );
};
