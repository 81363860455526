import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle
} from 'react';
import classNames from 'classnames';

export type EditableContentFieldInputProps = {
  className?: string;
  textAreaClassName?: string;
  defaultValue?: string;
  placeholder?: string;
  maxLength?: number;
  fieldName?: string;
  showCharacterCount?: boolean;
  autoResize?: boolean;
  maxTextFieldHeight?: number;
  locked?: boolean;
  onChange?: (value: string) => void;
};

export type EditableContentFieldHandle = {
  clearText: () => void;
  setText: (value: string) => void;
  focus: () => void;
};

const EditableContentFieldInput = forwardRef<
  EditableContentFieldHandle,
  EditableContentFieldInputProps
>(
  (props, ref): JSX.Element => {
    const {
      className,
      textAreaClassName,
      defaultValue,
      placeholder,
      maxLength = 1000,
      fieldName,
      showCharacterCount = true,
      autoResize = false,
      locked = false,
      maxTextFieldHeight = -1,
      onChange
    } = props;

    const [text, setText] = useState<string>('');
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleChange = useCallback(
      (value: string) => {
        setIsEdited(true);
        setText(value);
        onChange?.(value);
      },
      [onChange]
    );

    useImperativeHandle(ref, () => ({
      clearText: () => {
        setText('');
        setIsEdited(false);
        if (textAreaRef.current) {
          textAreaRef.current.value = '';
        }
      },
      setText: (value: string) => {
        if (textAreaRef.current) {
          textAreaRef.current.value = value;
        }
        handleChange(value);
      },
      focus: () => {
        textAreaRef.current?.focus();
      }
    }));

    const characterCountLabel = useMemo(() => {
      if (!maxLength) return null;
      return `${text.length}/${maxLength}`;
    }, [text, maxLength]);

    useEffect(() => {
      if (isEdited || !defaultValue) return;
      handleChange(defaultValue);
    }, [defaultValue, isEdited, handleChange]);

    useEffect(() => {
      if (!autoResize || !textAreaRef.current) return;
      textAreaRef.current.style.height = ''; // reset height for next calculation
      let newHeight = textAreaRef.current.scrollHeight + 3;
      if (maxTextFieldHeight > 0 && newHeight > maxTextFieldHeight) {
        newHeight = maxTextFieldHeight;
      }
      textAreaRef.current.style.height = `${newHeight}px`;
    }, [text, autoResize, maxTextFieldHeight]);

    return (
      <div className={classNames('editable-content-field-input', className)}>
        <textarea
          className={classNames('input-field', textAreaClassName)}
          name={fieldName}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={e => handleChange(e.target.value)}
          disabled={locked}
          ref={textAreaRef}>
          {defaultValue}
        </textarea>
        {showCharacterCount && characterCountLabel && (
          <p className='form-control-label small text'>{characterCountLabel}</p>
        )}
      </div>
    );
  }
);

EditableContentFieldInput.displayName = 'EditableContentFieldInput';

export default EditableContentFieldInput;
