import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { EmoteContextState, EmoteSet, EmoteDisplay } from '../types';
import emotesService from '../services/emotesService';
import emoteConstants from '../constants/emoteConstants';

export const EmotesContext = createContext<EmoteContextState | undefined>(undefined);

export const useEmotes = (): EmoteContextState => {
  const resource = useContext(EmotesContext);
  if (!resource) {
    throw new Error('useEmotes must be used within a EmotesProvider');
  }
  return resource;
};

export type EmotesProviderProps = {
  groupId: number;
  children: React.ReactNode;
};

const transformEmoteSetsToList = (emoteSets: EmoteSet[]): EmoteDisplay[] => {
  return emoteSets.reduce<EmoteDisplay[]>((acc, emoteSet) => {
    const emotes = emoteSet.emotes.map(emote => ({
      id: emote.id,
      name: emote.name,
      url: emoteConstants.emoteNameToUrl(emote.name)
    }));
    return acc.concat(emotes);
  }, []);
};

export function EmotesProvider({ children, groupId }: EmotesProviderProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorLoading, setErrorLoading] = useState<boolean>(false);
  const [emoteList, setEmoteList] = useState<EmoteDisplay[]>([]);

  const fetchGroupEmoteSets = useCallback(async () => {
    try {
      setIsLoading(true);
      setErrorLoading(false);
      const response = await emotesService.getGroupEmoteSets(groupId);
      setEmoteList(transformEmoteSetsToList(response.emoteSets));
    } catch {
      setErrorLoading(true);
    } finally {
      setIsLoading(false);
    }
  }, [groupId]);

  const getEmoteById = useCallback(
    (emoteId: string): EmoteDisplay | undefined => {
      return emoteList.find(emote => emote.id === emoteId);
    },
    [emoteList]
  );

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchGroupEmoteSets();
  }, [fetchGroupEmoteSets]);

  return (
    <EmotesContext.Provider
      value={{
        isLoading,
        errorLoading,
        emoteList,
        getEmoteById
      }}>
      {children}
    </EmotesContext.Provider>
  );
}
