import { Action, ActionType } from '../store/postAction';
import { PostStateType } from '../store/postContextProvider';

const postReducer = (state: PostStateType, action: Action): PostStateType => {
  switch (action.type) {
    case ActionType.BLOCK_USER:
      return {
        ...state,
        blockedUserList: [...state.blockedUserList, action.payload.userId]
      };
    case ActionType.CLEAR_BLOCKED_USERS:
      return {
        ...state,
        blockedUserList: []
      };
    default:
      return state;
  }
};

export { postReducer as default };
