import React from 'react';

export type CreatePostButtonProps = {
  label: string;
  disabled?: boolean;
  onClick: (() => void) | (() => Promise<any>);
};

const CreatePostButton = ({ label, disabled, onClick }: CreatePostButtonProps): JSX.Element => (
  <button
    type='button'
    className='group-forums-create-post-btn btn-growth-md'
    onClick={onClick}
    disabled={disabled}>
    <span className='icon-plus' />
    {label}
  </button>
);

CreatePostButton.displayName = 'CreatePostButton';

export default CreatePostButton;
