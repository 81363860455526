import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { Button } from 'react-style-guide';
import { groupsConfig } from '../translation.config';
import groupForumsConstants from '../constants/groupForumsConstants';

export type GroupWallDeprecationNoticeProps = {
  groupId: number;
} & WithTranslationsProps;

const GroupWallDeprecationNotice = ({
  groupId,
  translate
}: GroupWallDeprecationNoticeProps): JSX.Element => {
  return (
    <div className='group-wall-deprecation-notice'>
      <span className='group-wall-deprecation-notice-icon icon-warning' />
      <div className='group-wall-deprecation-notice-content'>
        <h3 className='group-wall-deprecation-notice-title'>
          {translate('Heading.GroupWallDeprecated')}
        </h3>
        <div className='group-wall-deprecation-notice-description'>
          {translate('Description.GroupWallDeprecated')}
        </div>
      </div>
      <a
        className='group-wall-deprecation-notice-button'
        href={groupForumsConstants.urls.getForumsPageUrl(groupId)}>
        <Button
          variant={Button.variants.primary}
          size={Button.sizes.small}
          width={Button.widths.full}
          onClick={() => null}>
          {translate('Action.VisitForums')}
        </Button>
      </a>
    </div>
  );
};

export default withTranslations(GroupWallDeprecationNotice, groupsConfig);
