import { useCallback, useState, useEffect } from 'react';
import forumsService from '../services/forumsService';
import { ForumCategory } from '../types';

type ForumCategoriesReturn = {
  isLoading: boolean;
  errorKey: string | null;
  forumCategories: Array<ForumCategory>;
};

const useForumCategories = (groupId: number): ForumCategoriesReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const [forumCategories, setForumCategories] = useState<ForumCategory[]>([]);

  const fetchForumCategories = useCallback(async () => {
    try {
      setIsLoading(true);
      setErrorKey(null);
      const response = await forumsService.getGroupForumCategories(groupId, false);
      setForumCategories(response.data);
    } catch {
      setErrorKey('REPLACEME');
    } finally {
      setIsLoading(false);
    }
  }, [groupId]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchForumCategories();
  }, [fetchForumCategories]);

  return {
    isLoading,
    errorKey,
    forumCategories
  };
};

export default useForumCategories;
