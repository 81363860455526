import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

export type ReactionEmoteProps = {
  className?: string;
  emoteUrl: string;
  size?: number;
  onClick?: () => void;
};

const getEmoteStyle = ({ emoteUrl, size }: { emoteUrl: string; size?: number }) => {
  return {
    backgroundImage: `url('${emoteUrl}')`,
    ...(size && { width: `${size}px`, height: `${size}px` })
  };
};

const ReactionEmote = ({ className, emoteUrl, size, onClick }: ReactionEmoteProps): JSX.Element => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (!onClick) return;
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        onClick();
      }
    },
    [onClick]
  );

  const clickProps = useMemo(
    () =>
      onClick
        ? {
            onClick,
            onKeyDown: handleKeyDown,
            ariaLabel: 'reaction',
            role: 'button'
          }
        : {},
    [onClick, handleKeyDown]
  );

  return (
    <span
      className={classNames('reaction-emote', !!onClick && 'reaction-emote-clickable', className)}
      style={getEmoteStyle({ emoteUrl, size })}
      {...clickProps}
    />
  );
};

export default ReactionEmote;
