import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { useSystemFeedback } from 'react-style-guide';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { ForumComment, Reaction } from '../types';
import { groupsConfig } from '../translation.config';
import UserDisplay from './UserDisplay';
import CommentReactions from './CommentReactions';
import groupForumsConstants, { CommentVariants } from '../constants/groupForumsConstants';
import forumsService from '../services/forumsService';
import { usePost } from '../contexts/PostContext';
import CommentReplies from '../containers/CommentReplies';
import useCursoredData from '../hooks/useCursoredData';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import PostMenu from './PostMenu';
import CommentMenu from './CommentMenu';
import { CompareComments } from '../utils/typeComparison';
import { logGroupForumsClickEvent } from '../utils/logging';

export type CommentProps = {
  id: string;
  createdBy: number;
  creatorDisplayName: string;
  createdAt: Date;
  content: string;
  title?: string;
  threadId: string | null; // The id of the channel with the thread of comments replying to this comment (if there is one)
  channelId: string; // The id of the channel this comment is in
  variant: CommentVariants;
  isActive: boolean;
  replies: ForumComment[];
  reactions: Reaction[];
  parentCommentId?: string;
} & WithTranslationsProps;

const MAX_REACTIONS_PER_LINE_DEFAULT = 4;
const MAX_REACTIONS_PER_LINE_REPLY = 3;

const Comment = ({
  id,
  threadId,
  channelId,
  title,
  variant,
  isActive,
  replies,
  createdBy,
  createdAt,
  content,
  parentCommentId,
  reactions,
  creatorDisplayName,
  translate
}: CommentProps): JSX.Element => {
  const history = useHistory();
  const { systemFeedbackService } = useSystemFeedback();
  const {
    groupId,
    categoryId,
    postId,
    post,
    setReplyToCommentOrPost,
    setReplyToCommentReply,
    addReplies,
    togglePostNotifications,
    toggleCommentNotifications,
    fetchPostNotificationPreference,
    fetchCommentNotificationPreference
  } = usePost();
  const { canReact, canCreateComment } = useForumPermissions();
  const [showReplies, setShowReplies] = useState<boolean>(false);

  const onDeletePost = () => {
    history.push(groupForumsConstants.router.getCategoryRoute(categoryId));
  };

  const fetchReplies = useCallback(
    async (cursor: string | null) => {
      if (threadId === null) {
        return { data: [], nextPageCursor: null, previousPageCursor: null };
      }
      const response = await forumsService.getGroupForumComments(
        groupId,
        categoryId,
        threadId,
        groupForumsConstants.pageCounts.commentsPerPage,
        cursor
      );
      if (response.data.length) {
        addReplies(id, response.data, false);
      }
      return response;
    },
    [addReplies, categoryId, groupId, id, threadId]
  );

  const {
    isLoadingInitialItems: isLoadingReplies,
    isFetchingNextPage: isFetchingNextRepliesPage,
    error: errorLoadingReplies,
    refetch: refetchReplies,
    fetchMore: fetchNextRepliesPage,
    hasMore: hasMoreReplies
  } = useCursoredData<ForumComment>({
    fetchItems: fetchReplies,
    initialCursor: null,
    compareFn: CompareComments
  });

  const showRepliesSection = useMemo(() => {
    return variant === CommentVariants.Comment && threadId !== null;
  }, [threadId, variant]);

  const onToggleCommentReaction = async (emoteId: string, togglingOn: boolean) => {
    try {
      await forumsService.toggleGroupForumReaction(
        groupId,
        variant === CommentVariants.Reply ? channelId : postId,
        id,
        emoteId,
        togglingOn
      );
      logGroupForumsClickEvent({
        groupId,
        clickTargetType: `toggleCommentReaction${togglingOn ? 'On' : 'Off'}`,
        clickTargetId: emoteId
      });
      return true;
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    }
    return false;
  };

  const handleShowReplies = useCallback(() => {
    refetchReplies();
    setShowReplies(true);
  }, [refetchReplies]);

  const handleReply = useCallback(() => {
    if (variant === CommentVariants.Reply && parentCommentId) {
      setReplyToCommentReply(parentCommentId, id, createdBy);
      logGroupForumsClickEvent({
        groupId,
        clickTargetType: 'replyToReply',
        clickTargetId: id
      });
    } else if (variant !== CommentVariants.Reply) {
      setReplyToCommentOrPost(id, createdBy);
      if (showRepliesSection && !showReplies && variant === CommentVariants.Comment) {
        handleShowReplies();
      }
      logGroupForumsClickEvent({
        groupId,
        clickTargetType: 'replyToComment',
        clickTargetId: id
      });
    }
  }, [
    createdBy,
    handleShowReplies,
    groupId,
    id,
    parentCommentId,
    setReplyToCommentOrPost,
    setReplyToCommentReply,
    showReplies,
    showRepliesSection,
    variant
  ]);

  const handleReplyKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        handleReply();
      }
    },
    [handleReply]
  );

  const handleShowRepliesClicked = useCallback(() => {
    handleShowReplies();
    logGroupForumsClickEvent({
      groupId,
      clickTargetType: 'showCommentReplies',
      clickTargetId: id
    });
  }, [groupId, id, handleShowReplies]);

  const handleMenuOpened = useCallback(() => {
    const isPost = variant === CommentVariants.Post;

    // TODO: uncomment when notifications backend is complete
    /* if (isPost) {
      fetchPostNotificationPreference();
    } else {
      fetchCommentNotificationPreference(id);
    } */

    logGroupForumsClickEvent({
      groupId,
      clickTargetType: isPost ? 'openPostMenuFromComment' : 'openCommentMenu',
      clickTargetId: id
    });
  }, [id, variant, groupId]);

  useEffect(() => {
    // If we add a reply to a comment and we still haven't fetched the replies
    // we should fetch them and open the replies section
    if (replies?.length && showReplies === false) {
      handleShowReplies();
    }
  }, [handleShowReplies, replies, showReplies]);

  useEffect(() => {
    if (errorLoadingReplies) {
      systemFeedbackService.warning(translate('NetworkError'));
    }
  }, [systemFeedbackService, translate, errorLoadingReplies]);

  const overflowButton = () => {
    return (
      <button
        type='button'
        className='group-forums-comment-dropdown-menu-button btn-generic-more-sm'
        title='more'
        onClick={handleMenuOpened}>
        <span className='group-forums-comment-overflow-icon' />
      </button>
    );
  };

  const togglePostNotificationsCallback = useCallback(() => {
    try {
      togglePostNotifications();
      systemFeedbackService.success(translate('Message.NotificationPreferenceUpdated'));
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    }
  }, [systemFeedbackService, togglePostNotifications, translate]);

  const toggleCommentNotificationsCallback = useCallback(() => {
    if (variant !== CommentVariants.Comment) {
      return;
    }
    try {
      toggleCommentNotifications(id);
      systemFeedbackService.success(translate('Message.NotificationPreferenceUpdated'));
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    }
  }, [id, variant, systemFeedbackService, toggleCommentNotifications, translate]);

  const renderMenu = () => {
    if (variant === CommentVariants.Post) {
      if (!post) return null;
      return (
        <PostMenu
          post={post}
          button={overflowButton()}
          onDelete={onDeletePost}
          onSubscribe={togglePostNotificationsCallback}
        />
      );
    }

    return (
      <CommentMenu
        button={overflowButton()}
        isReply={variant === CommentVariants.Reply}
        createdBy={createdBy}
        commentId={id}
        parentCommentId={parentCommentId}
        threadId={threadId}
        channelId={channelId}
        onSubscribe={toggleCommentNotificationsCallback}
      />
    );
  };

  return (
    <React.Fragment>
      <div
        className={classNames(
          'group-forums-comment',
          variant ? `group-forums-comment-variant-${variant}` : '',
          { 'group-forums-comment-active': isActive }
        )}>
        <div className='group-forums-comment-header'>
          <UserDisplay
            userId={createdBy}
            createdTime={createdAt}
            userDisplayName={creatorDisplayName}
          />
          <div className='group-forums-comment-menu'>{renderMenu()}</div>
        </div>
        {title && <h2 className='group-forums-comment-title'>{title}</h2>}
        <div className='group-forums-comment-content'>{content}</div>
        <div className='groups-forums-comment-metadata-section'>
          <div className='groups-forums-comment-metadata-reaction-section'>
            <CommentReactions
              initialReactions={reactions}
              onToggleReaction={onToggleCommentReaction}
              maxReactionPerLine={
                variant === CommentVariants.Reply
                  ? MAX_REACTIONS_PER_LINE_REPLY
                  : MAX_REACTIONS_PER_LINE_DEFAULT
              }
              viewOnly={!canReact}
            />
          </div>
          {canCreateComment && (
            <div
              role='button'
              tabIndex={0}
              onClick={handleReply}
              onKeyDown={handleReplyKeyDown}
              className='groups-forums-comment-metadata-reply-section'>
              <span className='group-forums-comment-reply-icon' />
              {translate('Action.Reply')}
            </div>
          )}
        </div>
      </div>
      {showRepliesSection && (
        <CommentReplies
          replies={replies}
          isOpen={showReplies}
          onShowReplies={handleShowRepliesClicked}
          onLoadMore={fetchNextRepliesPage}
          isLoading={isLoadingReplies}
          isFetchingMore={isFetchingNextRepliesPage}
          hasMore={hasMoreReplies}
          loadingError={errorLoadingReplies}
          parentId={id}
        />
      )}
    </React.Fragment>
  );
};

export default withTranslations(Comment, groupsConfig);
