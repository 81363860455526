import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';
import { ForumComment, ForumPost, NotificationPreferenceType, PostState } from '../types';
import forumsService from '../services/forumsService';
import groupForumsConstants from '../constants/groupForumsConstants';
import useCursoredData from '../hooks/useCursoredData';
import useForumCategories from '../hooks/useForumCategories';
import { CompareComments } from '../utils/typeComparison';
import { EditableContentFieldHandle } from '../components/EditableContentFieldInput';

export const PostContext = createContext<PostState | undefined>(undefined);

export const usePost = (): PostState => {
  const resource = useContext(PostContext);
  if (!resource) {
    throw new Error('usePost must be used within a PostProvider');
  }
  return resource;
};

export type PostProviderProps = {
  groupId: number;
  categoryId: string;
  postId: string;
  initialCommentId?: string;
  children: React.ReactNode;
};

export function PostProvider({
  children,
  groupId,
  categoryId,
  initialCommentId,
  postId
}: PostProviderProps): JSX.Element {
  const [isLoadingPost, setIsLoadingPost] = useState<boolean>(true);
  const [loadingPostError, setLoadingPostError] = useState<boolean>(false);
  const [createCommentError, setCreateCommentError] = useState<boolean>(false);
  const [commentModeratedError, setCommentModeratedError] = useState<boolean>(false);
  const [post, setPost] = useState<ForumPost | null>(null);
  const [replyToComment, setReplyToComment] = useState<string>('');
  const [replyCommentHighlight, setReplyCommentHighlight] = useState<string>('');
  const [replyingToAuthor, setReplyingToAuthor] = useState<number>(0);
  const [editingComment, setEditingComment] = useState<ForumComment | null>(null);
  const [editingCommentParent, setEditingCommentParent] = useState<ForumComment | null>(null);

  const { forumCategories } = useForumCategories(groupId);

  const categoryName = useMemo(() => {
    const category = forumCategories.find(c => c.id === categoryId);
    return category?.name || '';
  }, [categoryId, forumCategories]);

  const clearEditingComment = useCallback(() => {
    if (editingComment === null && editingCommentParent === null) {
      return;
    }
    setEditingComment(null);
    setEditingCommentParent(null);
    setReplyCommentHighlight('');
  }, [editingComment, editingCommentParent]);

  const commentComposerRef = useRef<EditableContentFieldHandle>(null);
  const commentScrollContainerRef = useRef<HTMLDivElement>(null);
  const mobileCommentScrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollToCommentComposer = useCallback(() => {
    commentComposerRef.current?.focus();
  }, [commentComposerRef]);

  const setReplyToCommentOrPost = useCallback(
    (commentId: string, authorId: number) => {
      if (
        replyToComment === commentId &&
        replyCommentHighlight === commentId &&
        replyingToAuthor === authorId
      ) {
        return;
      }
      clearEditingComment();
      setReplyToComment(commentId);
      setReplyCommentHighlight(commentId);
      setReplyingToAuthor(authorId);
      scrollToCommentComposer();
    },
    [
      clearEditingComment,
      replyCommentHighlight,
      replyToComment,
      replyingToAuthor,
      scrollToCommentComposer
    ]
  );

  const setReplyToCommentReply = useCallback(
    (parentCommentId: string, commentId: string, authorId: number) => {
      if (
        replyToComment === parentCommentId &&
        replyCommentHighlight === commentId &&
        replyingToAuthor === authorId
      ) {
        return;
      }
      clearEditingComment();
      setReplyToComment(parentCommentId);
      setReplyCommentHighlight(commentId);
      setReplyingToAuthor(authorId);
      scrollToCommentComposer();
    },
    [
      clearEditingComment,
      replyCommentHighlight,
      replyToComment,
      replyingToAuthor,
      scrollToCommentComposer
    ]
  );

  const clearReplyToComment = useCallback(() => {
    if (replyToComment === '' && replyCommentHighlight === '' && replyingToAuthor === 0) {
      return;
    }
    setReplyToComment('');
    setReplyCommentHighlight('');
    setReplyingToAuthor(0);
  }, [replyCommentHighlight, replyToComment, replyingToAuthor]);

  const markPostAsRead = async (
    postGroupId: number,
    postCategoryId: string,
    Id: string,
    lastSeenCommentId: string
  ) => {
    try {
      await forumsService.markGroupForumPostAsRead(
        postGroupId,
        postCategoryId,
        Id,
        lastSeenCommentId
      );
    } catch {
      // Intentionally ignoring errors here because marking the post as read is non-critical
      // and should not block the user experience if it fails.
    }
  };

  const fetchPost = useCallback(async () => {
    try {
      setIsLoadingPost(true);
      setLoadingPostError(false);
      const response = await forumsService.getGroupForumPostsByIds(groupId, categoryId, [postId]);
      setPost(response.data[0]);
    } catch {
      setLoadingPostError(true);
    } finally {
      setIsLoadingPost(false);
    }
  }, [categoryId, groupId, postId]);

  const fetchComments = useCallback(
    async (cursor: string | null) => {
      const response = await forumsService.getGroupForumComments(
        groupId,
        categoryId,
        postId,
        groupForumsConstants.pageCounts.commentsPerPage,
        cursor,
        initialCommentId
      );
      return response;
    },
    [categoryId, groupId, initialCommentId, postId]
  );

  const onAddComments = useCallback(
    async (newItems: ForumComment[]) => {
      // Mark latest comment as last seen
      await markPostAsRead(groupId, categoryId, postId, newItems[newItems.length - 1].id);
    },
    [categoryId, groupId, postId]
  );

  const {
    items: comments,
    isLoadingInitialItems: isLoadingComments,
    isFetchingNextPage: isFetchingNextCommentsPage,
    isFetchingPreviousPage: isFetchingPreviousCommentsPage,
    error: errorLoadingComments,
    refetch: refetchComments,
    fetchMore: fetchNextCommentsPage,
    fetchPrevious: fetchPreviousCommentsPage,
    addItems: addComments,
    updateItem: updateComment,
    setItems: setComments
  } = useCursoredData<ForumComment>({
    fetchItems: fetchComments,
    initialCursor: null,
    compareFn: CompareComments,
    onAddItems: onAddComments
  });

  const addReplies = useCallback(
    (commentId: string, newComments: ForumComment[], addToFront: boolean) => {
      const comment = comments.find(c => c.id === commentId);
      if (!comment) {
        return;
      }
      const updatedComment = { ...comment };
      if (!updatedComment.replies) {
        updatedComment.replies = [];
      }
      if (!updatedComment.threadId) {
        updatedComment.threadId = newComments[0].parentId;
      }
      if (!addToFront) {
        updatedComment.replies = [...updatedComment.replies, ...newComments];
      } else {
        updatedComment.replies = [...newComments, ...updatedComment.replies];
      }
      // Get rid of duplicates
      // This can happen when a user posts a reply on a thread that hasn't loaded yet
      // The loading will add the same reply to the thread
      updatedComment.replies = updatedComment.replies.filter(
        (currentComment, index, self) => self.findIndex(c => c.id === currentComment.id) === index
      );

      updateComment(updatedComment);
    },
    [comments, updateComment]
  );

  const removeComment = useCallback(
    (commentId: string) => {
      const comment = comments.find(c => c.id === commentId);
      if (!comment) {
        return;
      }
      const updatedComments = comments.filter(c => c.id !== commentId);
      setComments(updatedComments);
    },
    [comments, setComments]
  );

  const removeReply = useCallback(
    (parentCommentId: string, commentId: string) => {
      const parentComment = comments.find(c => c.id === parentCommentId);
      if (!parentComment) {
        return;
      }
      const reply = parentComment.replies.find(c => c.id === commentId);
      if (!reply) {
        return;
      }
      const updatedReplies = parentComment.replies.filter(c => c.id !== commentId);
      const updatedParentComment = { ...parentComment, replies: updatedReplies };
      updateComment(updatedParentComment);
    },
    [comments, updateComment]
  );

  const editComment = useCallback(
    (updatedComment: ForumComment) => {
      updateComment(updatedComment);
    },
    [updateComment]
  );

  const editReply = useCallback(
    (updatedComment: ForumComment, parentCommentId: string) => {
      const parentComment = comments.find(c => c.id === parentCommentId);
      if (!parentComment) {
        return;
      }
      const updatedReplies = parentComment.replies.map(c =>
        c.id === updatedComment.id ? updatedComment : c
      );
      const updatedParentComment = { ...parentComment, replies: updatedReplies };
      updateComment(updatedParentComment);
    },
    [comments, updateComment]
  );

  const setContentModerationError = (error: unknown) => {
    const typedError = error as { status: number };
    if (typedError.status === 400) {
      setCommentModeratedError(true);
    }
  };

  const handleCreateComment = useCallback(
    async ({ content }): Promise<boolean> => {
      setCreateCommentError(false);
      let repliesToCommentId;
      if (replyToComment && post && replyToComment !== post.firstComment.id) {
        repliesToCommentId = replyToComment;
      }
      try {
        const response = await forumsService.createGroupForumComment(
          groupId,
          categoryId,
          postId,
          content,
          repliesToCommentId
        );
        if (repliesToCommentId) {
          addReplies(repliesToCommentId, [response], false);
        } else {
          addComments({ newItems: [response], addToFront: false });
        }

        return true;
      } catch (error) {
        setCreateCommentError(true);
        setContentModerationError(error);
      }
      return false;
    },
    [replyToComment, post, groupId, categoryId, postId, addReplies, addComments]
  );

  const handleEditComment = useCallback(
    async ({ content }): Promise<boolean> => {
      setCreateCommentError(false);
      if (editingComment === null) return false;
      const commentId = editingComment.id;
      const channelId = editingCommentParent?.threadId ? editingCommentParent.threadId : postId;
      try {
        const response = await forumsService.updateGroupForumComment(
          groupId,
          categoryId,
          channelId,
          commentId,
          content
        );
        if (editingCommentParent?.threadId) {
          editReply(response, editingCommentParent.id);
        } else {
          editComment(response);
        }

        return true;
      } catch (error) {
        setCreateCommentError(true);
        setContentModerationError(error);
      }
      return false;
    },
    [categoryId, editComment, editReply, editingComment, editingCommentParent, groupId, postId]
  );

  const handleDeleteComment = useCallback(
    async (commentId: string, parentCommentId?: string): Promise<boolean> => {
      try {
        let channelId = postId;
        // If we are deleting a reply we send in the thread id as the channel id
        if (parentCommentId) {
          const parentComment = comments.find(c => c.id === parentCommentId);
          if (!parentComment) {
            return false;
          }
          if (!parentComment.threadId) {
            return false;
          }
          channelId = parentComment.threadId;
        }
        await forumsService.deleteGroupForumComment(groupId, categoryId, channelId, commentId);
        if (parentCommentId) {
          removeReply(parentCommentId, commentId);
        } else {
          removeComment(commentId);
        }
      } catch (error) {
        return false;
      }
      return true;
    },
    [postId, groupId, categoryId, comments, removeReply, removeComment]
  );

  const setEditComment = useCallback(
    (commentId: string, parentCommentId?: string) => {
      let currentEditComment: ForumComment | null = null;
      let editCommentParent: ForumComment | null = null;
      if (parentCommentId) {
        const parentComment = comments.find(c => c.id === parentCommentId);
        if (!parentComment) return;
        const comment = parentComment.replies.find(c => c.id === commentId);
        if (!comment) return;
        currentEditComment = comment;
        editCommentParent = parentComment;
      } else {
        const comment = comments.find(c => c.id === commentId);
        if (!comment) return;
        currentEditComment = comment;
      }

      clearReplyToComment();
      setReplyCommentHighlight(currentEditComment.id);
      setEditingComment(currentEditComment);
      setEditingCommentParent(editCommentParent);
      scrollToCommentComposer();
    },
    [clearReplyToComment, comments, scrollToCommentComposer]
  );

  const clearCommentErrors = () => {
    setCommentModeratedError(false);
    setCreateCommentError(false);
  };

  const fetchPostNotificationPreference = useCallback(async () => {
    if (!post) {
      return;
    }
    const result = await forumsService.getPostNotificationPreference(groupId, categoryId, postId);
    const updatedPost = { ...post, notificationPreference: result.preference };
    setPost(updatedPost);
  }, [categoryId, groupId, postId, post]);

  const fetchCommentNotificationPreference = useCallback(
    async commentId => {
      const comment = comments.find(c => c.id === commentId);
      if (!comment) {
        return;
      }

      const result = await forumsService.getCommentNotificationPreference(
        groupId,
        categoryId,
        postId,
        commentId
      );

      const updatedComment = { ...comment, notificationPreference: result.preference };

      updateComment(updatedComment);
    },
    [categoryId, comments, groupId, postId, updateComment]
  );

  const togglePostNotifications = useCallback(async () => {
    if (!post) {
      return;
    }

    const { notificationPreference } = post;
    const newIsSubscribed = notificationPreference === NotificationPreferenceType.None;

    await forumsService.togglePostNotificationSubscription(
      groupId,
      categoryId,
      postId,
      newIsSubscribed
    );

    const updatedPost = {
      ...post,
      notificationPreference: newIsSubscribed
        ? NotificationPreferenceType.All
        : NotificationPreferenceType.None
    };
    setPost(updatedPost);
  }, [groupId, categoryId, postId, post]);

  const toggleCommentNotifications = useCallback(
    async (commentId: string) => {
      const comment = comments.find(c => c.id === commentId);
      if (!comment) {
        return;
      }

      const { notificationPreference } = comment;
      const newIsSubscribed = notificationPreference === NotificationPreferenceType.None;

      await forumsService.toggleCommentNotificationSubscription(
        groupId,
        categoryId,
        postId,
        commentId,
        newIsSubscribed
      );

      const updatedComment = {
        ...comment,
        notificationPreference: newIsSubscribed
          ? NotificationPreferenceType.All
          : NotificationPreferenceType.None
      };
      updateComment(updatedComment);
    },
    [groupId, categoryId, postId, comments, updateComment]
  );

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchPost();
  }, [fetchPost]);

  useEffect(() => {
    refetchComments();
  }, [refetchComments]);

  return (
    <PostContext.Provider
      value={{
        groupId,
        categoryId,
        categoryName,
        postId,
        initialCommentId,
        isLoadingPost,
        loadingPostError,
        fetchPost,
        post,
        createCommentError,
        commentModeratedError,
        handleCreateComment,
        handleEditComment,
        replyToComment,
        replyCommentHighlight,
        replyingToAuthor,
        setReplyToCommentOrPost,
        setReplyToCommentReply,
        clearReplyToComment,
        isLoadingComments,
        refetchComments,
        isFetchingNextCommentsPage,
        isFetchingPreviousCommentsPage,
        fetchNextCommentsPage,
        fetchPreviousCommentsPage,
        fetchPostNotificationPreference,
        fetchCommentNotificationPreference,
        togglePostNotifications,
        toggleCommentNotifications,
        errorLoadingComments,
        comments,
        addReplies,
        clearCommentErrors,
        handleDeleteComment,
        editingComment,
        editingCommentParent,
        setEditComment,
        clearEditingComment,
        commentComposerRef,
        commentScrollContainerRef,
        mobileCommentScrollContainerRef
      }}>
      {children}
    </PostContext.Provider>
  );
}
