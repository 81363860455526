import { EnvironmentUrls } from 'Roblox';

const { accountSettingsApi } = EnvironmentUrls;

export default {
  urls: {
    blockUser(profileId: number): string {
      return `${accountSettingsApi}/v1/users/${profileId}/block`;
    }
  }
};
