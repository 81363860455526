import { DeviceMeta } from 'Roblox';
import React, { useCallback, useEffect, UIEvent } from 'react';
import { useSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { groupsConfig } from '../translation.config';
import PostSkeleton from '../components/PostSkeleton';
import CommentsSection from './CommentsSection';
import SectionHeader from '../components/SectionHeader';
import groupForumsConstants, { CommentVariants } from '../constants/groupForumsConstants';
import CommentComposer from '../components/CommentComposer';
import Comment from '../components/Comment';
import { PostProvider, usePost } from '../contexts/PostContext';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import PostNavigation from '../components/PostNavigation';
import usePostContext from '../hooks/usePostContext';

export type PostProps = {
  groupId: number;
  categoryId: string;
  postId: string;
  initialCommentId?: string;
} & WithTranslationsProps;

const Post = ({ translate }: WithTranslationsProps): JSX.Element => {
  const history = useHistory();
  const {
    categoryId,
    categoryName,
    isLoadingPost,
    post,
    loadingPostError,
    createCommentError,
    replyCommentHighlight,
    fetchPost,
    commentModeratedError,
    commentScrollContainerRef,
    mobileCommentScrollContainerRef
  } = usePost();
  const { canCreateComment } = useForumPermissions();
  const { SystemFeedbackComponent, systemFeedbackService } = useSystemFeedback();
  const { state } = usePostContext();

  useEffect(() => {
    // If we are on the page of a post which you blocked a user, navigate away from it.
    if (post?.createdBy && state.blockedUserList.includes(post.createdBy)) {
      history.replace(groupForumsConstants.router.getCategoryRoute(categoryId));
    }
  }, [state.blockedUserList, post?.createdBy, categoryId, history]);

  useEffect(() => {
    if (createCommentError) {
      systemFeedbackService.warning(translate('NetworkError'));
    }
  }, [systemFeedbackService, translate, createCommentError]);

  const showCommentComposer = canCreateComment && post && !post.isLocked;

  const onBack = useCallback(() => {
    history.push(groupForumsConstants.router.getCategoryRoute(categoryId));
  }, [categoryId, history]);

  useEffect(() => {
    if (!isLoadingPost && !post && !loadingPostError) {
      onBack();
    }
  }, [isLoadingPost, loadingPostError, onBack, post]);

  const renderPostContent = () => {
    if (loadingPostError) {
      return (
        <div className='group-forums-categories-error section-content-off'>
          <span className='icon-status-alert' />
          <h2>{translate('Error.LoadPostTitle')}</h2>
          <span className='group-forums-categories-error-subtitle'>
            {translate('Error.ReloadingSubtitle')}
          </span>
          <button type='button' className='btn-primary-md' onClick={fetchPost}>
            {translate('Action.RetryLoadingPost')}
          </button>
        </div>
      );
    }

    if (isLoadingPost || !post) {
      return <PostSkeleton />;
    }

    return (
      <Comment
        title={post.name}
        variant={CommentVariants.Post}
        isActive={replyCommentHighlight === post.firstComment.id}
        id={post.firstComment.id}
        createdBy={post.firstComment.createdBy}
        createdAt={post.firstComment.createdAt}
        creatorDisplayName={post.firstComment.creatorDisplayName}
        content={post.firstComment.content.plainText}
        threadId={null}
        channelId={post.firstComment.parentId}
        reactions={post.firstComment.reactions}
        replies={[]}
      />
    );
  };

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    if (DeviceMeta().isPhone) {
      const element = event?.target as HTMLDivElement;
      if (element.scrollTop > 0) {
        // Scroll the entire page down so that scroll up gestures in the comments section aren't treated as page refreshes.
        document.documentElement.scrollTop = 10;
      } else {
        document.documentElement.scrollTop = 0;
      }
    }
  };

  return (
    <div className='group-forums-post'>
      <PostNavigation
        categoryName={categoryName}
        backRoute={groupForumsConstants.router.getCategoryRoute(categoryId)}
        postTitle={post?.name}
        containerClassName='hide-on-native'
      />
      <SectionHeader
        headerText={categoryName}
        onBack={onBack}
        containerClassName='show-on-native'
      />
      <div className='group-forums-post-content'>{renderPostContent()}</div>
      <div className='group-forums-post-divider' />
      <div
        onScroll={handleScroll}
        className='group-forums-post-comments-container'
        ref={mobileCommentScrollContainerRef}>
        <div
          ref={commentScrollContainerRef}
          className={classNames('group-forums-post-comments-section', {
            'group-forums-post-comments-section-reply-to-comment':
              !!replyCommentHighlight && canCreateComment,
            'group-forums-post-comments-section-comment-moderated':
              commentModeratedError && canCreateComment,
            'group-forums-post-comments-section-no-composer': !canCreateComment
          })}>
          <CommentsSection />
        </div>
        {showCommentComposer && (
          <div className='group-forums-post-comment-composer'>
            <CommentComposer />
          </div>
        )}
      </div>
      <SystemFeedbackComponent />
    </div>
  );
};

const PostWithProvider = (props: PostProps) => (
  <PostProvider {...props}>
    <Post {...props} />
  </PostProvider>
);
export default withTranslations(PostWithProvider, groupsConfig);
