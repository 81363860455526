import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupsConfig } from '../translation.config';

export type GroupForumsEarlyAccessNoticeProps = {} & WithTranslationsProps;

const GroupForumsEarlyAccessNotice = ({
  translate
}: GroupForumsEarlyAccessNoticeProps): JSX.Element => {
  return (
    <div className='group-forums-early-access-notice'>
      <span className='group-forums-early-access-notice-icon icon-remove' />
      <div className='group-forums-early-access-notice-content'>
        <h3 className='group-forums-early-access-notice-title'>
          {translate('Heading.GroupForumsEarlyAccess')}
        </h3>
        <div className='group-forums-early-access-notice-description'>
          {translate('Description.GroupForumsEarlyAccess')}
        </div>
      </div>
    </div>
  );
};

export default withTranslations(GroupForumsEarlyAccessNotice, groupsConfig);
