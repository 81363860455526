import React, { useCallback, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { useSystemFeedback } from 'react-style-guide';
import { groupsConfig } from '../translation.config';
import { Group } from '../../shared/types';
import forumsService from '../services/forumsService';
import { logGroupForumsClickEvent } from '../utils/logging';

export type GroupForumsUpsellProps = {
  group: Group;
  onFinished: () => void;
} & WithTranslationsProps;

const GroupForumsUpsell = ({
  group,
  onFinished,
  translate
}: GroupForumsUpsellProps): JSX.Element | null => {
  const { systemFeedbackService } = useSystemFeedback();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const forumsGetStarted = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        forumsService.createGroupForumCategory(group.id, translate('Label.HelpAndSupport')),
        forumsService.createGroupForumCategory(group.id, translate('Label.Feedback')),
        forumsService.createGroupForumCategory(group.id, translate('Label.GeneralDiscussions'))
      ]);
      logGroupForumsClickEvent({
        groupId: group.id,
        clickTargetType: 'forumsGetStarted'
      });
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    } finally {
      onFinished();
      setIsLoading(false);
    }
  }, [group.id, translate, setIsLoading, onFinished, systemFeedbackService]);

  return (
    <div className='section-content'>
      <div className='group-forums-upsell-container'>
        <div className='group-forums-upsell-banner' />
        <h2>{translate('Heading.ForumsUpsell')}</h2>
        <span className='text-label'>{translate('Description.ForumsUpsell')}</span>
        <button
          disabled={isLoading}
          type='button'
          className='btn-growth-md btn-min-width group-forums-start-button'
          onClick={forumsGetStarted}>
          <span className='font-bold'>{translate('Action.GetStarted')}</span>
        </button>
      </div>
    </div>
  );
};

export default withTranslations(GroupForumsUpsell, groupsConfig);
